.container {
    width: 349px;
    height: 480px;
    border-radius: 15px;
    overflow: hidden;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;      
    background-image: linear-gradient(139.73deg, #FFAC17 4.08%, #F17F01 79.61%);
}

.container > * {
    margin-bottom: 0px;
}

.title {
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 22px;
    /* identical to box height, or 91% */

    letter-spacing: 0.2px;
    color: var(--white);

    margin-top: 48px;
}

.time, .time > * {
    margin: 0px;
    padding: 0px;
}

.time {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 40px;/*159px;/* figma 159px percent 33.125%*/
    width: 84.31232091690544%;/*294.25px*/
    height: 307px;
    position: relative;
}

.timeText {
    font-style: normal;
    font-weight: 700;
    font-size: 42.5279px;
    line-height: 42.5279px;/*64px;*/
    text-align: center;

    color: var(--white);
}


.timeUnit {
    font-style: normal;
    font-weight: 400;
    font-size: 20.6969px;
    line-height: 20.6969px;/*31px;*/
    text-align: center;

    color: var(--white); 
}

.backgroundContainer{
    width: 100%;
    height: 100%;

    position: absolute;
    left: 0px;
    top: 0px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.backgroundImage {
    width: 100%;
    height: auto;
}

@media screen and (min-width:601px) and (max-width: 860px) {
    .container {
        width: calc(50% - 10px);
    }

    .title {
        font-size: 20px;
        text-align: center;
    }    
}

@media screen and (max-width: 600px) {
    .container {
        width: 100vw;
        height: 269px;
        border-radius: 0px;      
        margin-top: 10px;
    }

    .title {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        /* identical to box height, or 125% */

        margin-top: 25px;
        margin-bottom: 0px;
    }

    .time {
        width: 43.71980676328502vw;
        height: 65.27335640138408%;/*414px;*/
        margin-top: 14px;/*87.2px;*/
    }

    .timeText {
        margin-top: 0px;
        font-size: 26.1602px;
        line-height: 39px;
    }

    .timeUnit {
        font-size: 12.7313px;
        line-height: 19px;
    }
}