.container {
    width: calc(100vw - 93px);/*100%;*/
    max-width: calc(100vw - 93px);
    min-height: 100vh;
    background-color: var(--dark);
    font-family: "Poppins";
    color: var(--white);

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 50px;

    /* padding-left: 160px; */
}

.container > h3 {
    margin-top: 15px;
    text-align: center;
    color:var(--white);
}


.topTitle {
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 22px;
    color: var(--font-2);
    margin: 0px;
    margin-top: 20px;
    /* position: absolute;
    left: 0px;
    top: 32px; */
}

.boxContainer {
    width: calc(100% - 321px);
    /* height: 536px; */
    background: var(--main-bg);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 15px;
    padding: 25px;
    /*overflow: hidden;*/
    margin-top: 20px;
}

.boxTitle {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.2px;
    color: #AB8DFF;

}

.boxDescription {
    font-weight: 400;
    letter-spacing: 0.2px;
    font-size: 13px;
    color: var(--font-1);

}

.boxContent {
    margin-top: 20px;
    padding: 10px;
    width: 100%;
    border: 1px solid #AB8DFF;
    border-radius: 10px;
    flex-direction: row;
    position: relative;
}

.boxContent > p {
    font-size: small;
    margin-bottom: 0px;
}


.boxContent:hover{
    border: 1px solid var(--white);
    cursor:pointer;
}

.boxContent > a {
    all: unset;
}

.boxContent .smallDescription {
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 20px;
    color: var(--font-1);
}

.nextArrow {
    width: 100%;
    height: 18px;
    bottom: 10px;
    right: 15px;
    /* background-color: #34216B; */
    display: flex;
    justify-content: flex-end;
    align-items: center; 
    position: absolute;   
}

.nextArrow > svg {
    /* margin-right: 15px; */
    cursor: pointer;
}


.logoutButton {
    /* width: 222px;
    height: 66px; */

    background: linear-gradient(152.91deg, #865ACE 3.01%, #4800E2 100%);
    border-radius: 12px;

    margin-top: 20px;
    margin-bottom: 20px;
    margin-right: 160px;
    cursor: pointer;

    /* transition: all 0.25s; */
    /* pointer-events: none; */

    display: flex;
    flex-direction: column;
    justify-content: center; 
    align-self: flex-end;

    padding: 15px 30px 15px 30px;
    /* opacity: 0; */
}

.logoutText {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 13px;

    color: var(--white);
}

@media screen and (min-width:601px) and (max-width: 860px) {
    .container {
        /* width: calc(50% - 10px); */
        align-items: center;
    }
    .boxContainer {
        width: calc(100% - 70px);
    }
    .logoutButton {
        margin-right: 35px;
    }
}

@media screen and (max-width:600px) {
    .container {
        min-width: 100vw;
        /* min-height: calc(100vh - 143.79px); */
        justify-content: center;
        margin-top: 0px;
        min-height: auto;
    }
    .topTitle {
        position: static;
        width: 100%;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 40px;
        /* identical to box height, or 222% */
        padding-left: 22px;
        padding-top: 24px;
        padding-bottom: 9px;

        margin-bottom: 0px;
    }
    .boxContainer {
        width: 90vw;
    }
    .logoutButton {
        margin-right: 20px;
        margin-bottom: 20px;
    }
    

}


