.container {
  width: 54px;
  height: 30px;

  background: rgba(250, 251, 252, 0.1);
  /*border: 1px dashed rgba(255, 255, 255, 0.2);*/
  backdrop-filter: blur(4px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  transition: all 0.25s;

  pointer-events: all;
}

.closed,
.closed * {
  opacity: 0;
  pointer-events: none;
}

.radar::before,
.heatmap::before,
.real::before,
.test::before {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-align: right;

  color: var(--white);

  position: absolute;
  right: 68px;
  width: 64px;
  pointer-events: none;
}

.radar,
.real {
  justify-content: flex-start;
}

.radar::before {
  content: "Radar";
}

.real::before {
  content: "Real";
}

.heatmap,
.test {
  justify-content: flex-end;
}

.heatmap::before {
  content: "Heatmap";
}

.test::before {
  content: "Test";
}

.switch {
  width: 26px;
  height: 26px;
  border-radius: 13px;
  margin: auto 2px;

  background: linear-gradient(159.18deg, #865ace 13.78%, #5c0fff 85.58%);
  transition: all 0.25s;
  cursor: pointer;
}
