.topToolbar {
  padding-top: 30px;
  margin-bottom: 21px;
  width: 89.16666667vw;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-wrap: wrap;
}

.topTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 22px;
  color: var(--font-4);
  /*margin: 0px;
    padding-top: 0px;*/
  position: absolute;
  left: 6px;
  top: 32px;
}

.allButtons {
  width: auto;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.buttons {
  width: auto; /*239px;*/
  height: 45px;
  border-radius: 10px;
  background: rgba(250, 251, 252, 0.1);
  /*border: 1px dashed rgba(255, 255, 255, 0.1);*/
  backdrop-filter: blur(4px);
  overflow: hidden;

  /*position: absolute;
    left: 40.97222222vw;
    top:21px;*/

  position: relative;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  overflow: visible;
}

.buttons > .basicButton,
.movement,
.button {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttons > .basicButton {
  width: 108px;
  height: 45px;

  border-radius: 10px;
  border: none;

  font-weight: 400; /*600;*/
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  text-align: center;

  color: var(--font-2);
}

.buttons > .movement {
  width: 131px;
  height: 45px;

  border-radius: 10px;
  border: none;

  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  text-align: center;

  color: var(--font-2);
}

.button {
  width: auto;
  height: 45px;
  padding-left: 30px;
  padding-right: 30px;

  border-radius: 10px;
  border: none;

  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  text-align: center;

  color: var(--font-2);
}

.dateTimeDialog {
  width: fit-content; /*100%;    */
  height: auto;

  border-radius: 14px;

  padding: 20px 15px 22px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: var(--bg-1);
  padding-top: 10px;
}

.dateTimeTitle {
  color: var(--white);
  text-align: center;
  font-size: 20px;
}

.dateTimeSelect {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

.timeSelectLabel {
  color: var(--white);
  margin-right: 5px;
  text-align: left;
  width: 100%;
  margin-bottom: 5px;
  font-size: 16px;
}

.dateTimeStyle {
  height: auto;
  padding: 10px;
  all: initial;
  font-family: "Poppins";
  background-color: var(--font-1);
  width: 100%;
}

.dateTimeButton {
  width: 48%;
  height: 35px;
  padding: 15px;

  border-radius: 8px;
  border: none;

  font-weight: 400;
  font-size: 16px;
  line-height: 13px;
  /* identical to box height */

  display: flex;
  justify-content: center;
  align-items: center;

  text-align: center;
  color: #c8bde8;
  cursor: pointer;

  transition: all 0.25s;
  min-width: 90px;

  margin-top: 10px;
  /* margin-bottom: 10px; */
}

.dateTimeOk {
  margin-right: 2.5px;
  border: 1px solid #9603ff;
}

.dateTimeOk:hover {
  background-color: #9603ff;
}

.dateTimeCancel {
  margin-left: 2.5px;
  border: 1px solid red;
}

.dateTimeCancel:hover {
  background-color: red;
}

.active {
  background: linear-gradient(152.91deg, #865ace 3.01%, #4800e2 100%);
  font-weight: 600 !important;
  color: var(--white) !important;
}

.pauseButton {
  background-size: 1rem 1rem;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  height: 1rem;
  width: 1rem;
  margin-right: 4px;
}

@media screen and (max-width: 600px) {
  .topToolbar {
    width: 100vw;
    height: auto; /* 113px;*/
    padding: 0px;
  }

  .topTitle {
    position: static;
    width: 100%;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 40px;
    /* identical to box height, or 222% */
    padding-left: 22px;
    padding-top: 24px;
    padding-bottom: 9px;

    margin-bottom: 0px;
  }

  .allButtons {
    width: 100%;
    padding: 0px;
    margin: 0px;
    justify-content: center;
  }

  .buttons {
    /* width: 50%; */
    margin-top: 0px;
  }

  .buttons > .basicButton,
  .buttons > .movement,
  .button > .button {
    width: 50%;
    max-width: 50%;
    overflow: hidden;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
  }

  .buttons > .button {
    min-width: 50%;
    padding-left: 10px;
    padding-right: 10px;
    font-weight: 600;
    font-size: 14px;
  }

  .dateTimeDialog {
    width: 90vw;
  }
}
