.container {
    width: 48.80952381%;/*410px;*/
    height: 304px;

    background: #372766;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.container > * {
    width: 95.12195122%;/*390px;*/
    margin-left: 20px;
}

.title {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */

    letter-spacing: 0.2px;

    color: var(--font-1);
    margin-top: 23px;
}

.visAndLegend {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;

    margin-top: 33px;
    /*height: 197.5px;/*205px - 7.5px : 7.5px is calculated from line height of axis labels*/;
    height: 64.80263157894737%;
}

.vis {
    flex: 1 1 186px;/*202px - 16px - figma distance between vis and legend*/
    margin-right: 16px;
}

.legend {
    flex: 1 1 188px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.legendRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    height: auto;/*28px;*/
}

.legendLabel {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    /* or 125% */

    text-align: center;
    letter-spacing: 0.2px;

    color: var(--font-1);
}

.legendContent {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    /* or 125% */

    color: var(--font-2);
    margin-left: 8px;
}

@media screen and (min-width:601px) and (max-width: 1024px) {
    .container {
        width: 100%;
        height: 152px;
    }

    .container:last-of-type {
        margin-top: 10px;
    }

    .title {
        margin-top: 10px;
    }
    .visAndLegend {
        margin-top: 10px;
    }

    
}

@media screen and (min-width: 601px) and (max-width: 773px) {
    .container {
        height: 170px;
    }

    .container:last-of-type {
        margin-top: 0px;
    }
}

@media screen and (min-width:601px) and (max-width: 860px) {
    .legendLabel, .legendContent {
        font-size: 13px;
        line-height: 15px;
    }
}

@media screen and (max-width: 600px) {
    .container {
        width: 100%;
        height: 290px;
        align-items: center;
        border-radius: 0px;
        border-top: 1.6px solid #462D90;
        background-color: rgba(55, 39, 102, 0);
    }

    .container:last-of-type {
        margin-bottom: 6.83090705487122vh;/*61px*/
    }
    .container > * {
        width: 81.64251207729469vw;/*91.35135135135135%;*/
        margin-left: 0px;
    }

    .title {
        font-weight: 600;
        font-size: 14px;

        margin-top: 24px;
        margin-bottom: 0px;
        
    }

    .visAndLegend {
        margin-top: 23px;
        height: 205px;
    }

    .vis {
        flex: 1 1 48.55072463768116vw;
    }

    .legend {
        flex: 1 1 auto;
    }

    .legendRow:first-child {
        margin-top: 37px;
    }

    .legendRow {
        height: auto;
    }
}