.container {
    width: calc(100% - 321px);
    /* height: 536px; */
    background: var(--main-bg);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 15px;
    padding: 20px;
    /*overflow: hidden;*/
    margin-bottom: 20px;
}

.container > *, .container > .topBar > * {
    margin-bottom: 5px;
}

.container > .topBar {
    width: 96.52631579%;/*917px;*/
    /* display: flex;
    justify-content: space-between;
    align-items: center;
    height: auto;
    margin-top: 27px; */
}

.topTitle {
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 28px;
    color: #C8BDE8;
    margin: 0px;
    margin-top: 20px;
    margin-bottom: 20px;
    /* position: absolute;
    left: 0px;
    top: 32px; */
}

.title {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #ECE6FF;
    height: 41px;
}
.boxTitle {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.2px;
    color: #AB8DFF;
}

.boxDescription {
    font-weight: 400;
    letter-spacing: 0.2px;
    font-size: 13px;
    color: #ECE6FF;
    margin-top: 10px;
}

.containerTier {
    width: 100%;
    display: flex;
    flex-direction:row;
    align-items: center;
    justify-content: space-around;
}

.boxTier {
    margin: 20px 10px 10px 0px;
    padding: 15px;
    width: 30%;
    height: 50vh;
    border: 1px solid #AB8DFF;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    position: relative;
}
.boxTier > div {
    display: flex;
    flex-grow: 1;
    text-align: center;
    flex-direction: column;
}

.boxTier > h3, h4 {
    color: #ECE6FF;
    text-align: center;
}

.boxTier ul {
    list-style-position: inside;
    list-style-type: disc;
    margin-top: 10px;
}


.editButton {
    background: linear-gradient(152.91deg, #865ACE 3.01%, #4800E2 100%);
    border-radius: 12px;

    margin-top: 20px ;
    cursor: pointer;

    display: flex;
    flex-direction: column;
    justify-content: center; 
    align-self: flex-end;
    padding: 15px 30px 15px 30px;
    
}

.editText {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 13px;

    color: #FFFFFF;
}

.upgradeBox {
    background: linear-gradient(152.91deg, #865ACE 3.01%, #4800E2 100%);
    border-radius: 12px;
    cursor: pointer;
    position: absolute;
    bottom: 15px;
    font-size: 13px;
    padding: 10px 20px 10px 20px;
    margin: 0 auto;
    left: 0;
    right: 0;
    width: 60%;
}

.upgradeBox:hover {
    outline-color: transparent;
    outline-style:solid;
    box-shadow: 0 0 0 2px #4800E2;
}

.currentBox {
    background: grey;
    border-radius: 12px;
    position: absolute;
    bottom: 15px;
    font-size: 13px;
    padding: 10px 20px 10px 20px;
    margin: 0 auto;
    left: 0;
    right: 0;
    width: 60%;
}

@media screen and (min-width:601px) and (max-width: 860px) {
    .container {
        width: calc(100% - 70px);
        margin-top: 20px;
    }
    .boxTier {
        height: 40vh;
    }
}

@media screen and (min-width:601px) and (max-width:800px) {
    .container > .topBar {
        flex-direction: column;
        align-items: flex-start;
    }

    .topTitle {
        padding-bottom: 10px;
    }

    .tableContainer, .devTable {        
        max-height: 375px;
    }
    .title {
        padding-bottom: 10px;
    }

}



@media screen and (max-width:600px) {
    .container {
        width: 90vw;
        /* min-height: 587px; */
        margin: 10px 0px 0px;
        padding: 15px;
        border-radius: 10px;
        margin-bottom: 90px;
    }

    /* .container > *, .container > .topBar {
        width: 100%;
    } */

    .topBar {
        display:flex;        
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        /* margin-top: 22px; */
    }


    .mainContainer {
        min-width: 100vw;
        min-height: calc(100vh - 143.79px);
        justify-content: center;
        margin-top: 0px;
    }
    .title {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 14px;
        /* identical to box height, or 125% */
                
        letter-spacing: 0.2px;
        width: 100%;
        padding-left: 36px;
    }
    .inputContainer {
        flex-grow: 0;
    }    
    .containerTier {
        align-items: flex-start;
        display: block;
    }
    .boxTier {
        width: 100%;
        height:auto;
        padding-bottom: 60px;
        margin-right: 0px;
    }

}
