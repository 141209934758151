.container {
  width: calc(100vw - 93px); /*100%;*/
  max-width: calc(100vw - 93px);
  min-height: 100vh;
  background-color: var(--dark);
  font-family: "Poppins";
  color: #ffffff;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 50px;
}

.mobileContainer {
  background-color: var(--dark);
}

.topAnalytics {
  width: 95.10022272%; /*89.16666667vw;*/
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  overflow: hidden;
}

.topLeftAnalytics,
.topLeftClosed {
  /* display: flex; */
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.topLeftAnalytics {
  flex: 1 1 390px;
}

.topLeftClosed {
  flex: 1 1 0px;
}

.topRightAnalytics,
.topRightFullwidth {
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 733px;
  /* overflow: hidden; */
  transition: all 0.25s;
}

.topRightAnalytics {
  flex: 1 1 calc(100% - 390px);
  /*width: calc(100% - 390px);*/
}

.topRightFullwidth {
  flex: 1 1 calc(100%);
  /*width: 100%;*/
}

.rightAnalytics {
  position: absolute;
  right: 0px;
  top: 0px;

  width: 19.93055556vw; /*287px;*/
  height: auto;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end; /*center;*/

  pointer-events: none;
  /*overflow: hidden;*/
}

/*page width on figma without sidebar : 1347px*/

.rowAnalytics {
  /* pixel width on figma : 1281px*/
  width: 95.10022272%; /*88.95833333vw;*/
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 0px;
}

.secondRowRightAnalytics {
  margin: 0px;
  padding: 0px;
  width: fit-content;
  height: 536px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.rowAnalytics:last-of-type {
  margin-bottom: 156px;
}

@media screen and (min-width: 601px) and (max-width: 860px) {
  .secondRowRightAnalytics {
    width: calc(50% - 10px);
  }
}

@media screen and (min-width: 601px) and (max-width: 900px) {
  .topAnalyticsMidScreen {
    width: 89.16666666666667vw;
    height: 733px;
    display: flex;

    position: relative;
    overflow: hidden;
  }

  .topRightAnalytics {
    flex: 1 1 100%;
    width: 100%;
  }

  .topLeftAnalytics {
    position: absolute;
    left: 0px;
    top: 0px;
    background-color: rgba(40, 27, 79, 0);
    transition: all 0.5s;
  }

  .topLeftClosed {
    top: -708px; /*733 - 25*/
    background-color: rgba(40, 27, 79, 1);
    border-radius: 0px 0px 40px 40px;
  }

  .topLeftClosed > *:not(:last-child) {
    opacity: 0;
    pointer-events: none;
  }

  .leftSwipe {
    width: 390px;
    height: 25px;
    background-color: var(--main-bg);
    border-radius: 0px 0px 40px 40px;

    position: absolute;
    left: 0px;
    bottom: 0px;

    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
  }

  .swipeBars {
    width: 390px;
    height: 25px;
  }

  .closeLeftIcon {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    position: absolute;
    left: 17px;
    top: 4px;
    background-color: var(--bg-4);

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
  }
}

/*Need specific breakpoints for swipe bars since used by mid and mobile breakpoints*/
@media screen and (max-width: 900px) {
  .swipeBars {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .swipeTop {
    margin-top: 13px;
  }

  .swipeBottom {
    margin-bottom: 0px;
  }

  .swipeDecor {
    width: 32px;
    height: 1px;
    background-color: #9b85da;
  }

  .swipeDecor:last-child {
    margin-top: 4px;
  }
}

@media screen and (max-width: 600px) {
  .container {
    width: 100vw;
    height: calc(100vh - 143.79px);

    overflow: hidden;
  }

  .container > * {
    width: 100vw;
    margin: 0px;
    padding: 0px;
  }

  .topAnalytics {
    width: 100vw;
    height: auto; /*calc(100vh - 256.79px);*/
    margin: 0px;
    padding: 0px;
  }

  .topLeftAnalytics {
    width: 0px;
    flex: 1 1 0vw;
    overflow: hidden;
  }

  .topRightAnalytics {
    flex: 1 1 100%;
    width: 100vw;
    height: auto; /*calc(100vh - 143.79px);*/
  }

  .rowAnalytics {
    flex-direction: column;
    max-width: 100vw;
    overflow: hidden;
  }

  .secondRowRightAnalytics {
    margin: "10px 0px 0px";
  }

  .mobileContainer {
    width: 100vw;
    height: 83.89809630459127vh; /*calc(100vh - 143.79px);*/

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
  }

  .swipeContainer {
    width: 100vw;
    height: 89px;
    min-height: 89px;
    position: absolute;
    left: 0px;
    /*bottom: 63.79px;*/
    top: calc(100vh - 152.79px); /*740px;*/
    background: var(--main-bg);
    border-radius: 40px 40px 0px 0px;

    transition: all 0.25s;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .swipeBars {
    width: 100vw;
    height: auto;
  }

  .swipeOpen {
    /*bottom: 0px;*/
    top: 45.24076147816349vh; /*404px;*/
    height: 54.75923852183651vh; /*489px;*/
  }

  .swipeOpenFull {
    /*bottom: 0px;*/
    top: 35px;
    min-height: calc(100vh - 35px);
    border-radius: 0px;

    transition: all 0.5s;
    background-color: var(--bg-4);
  }

  .swipeTitle {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 18px; /*40px*/
    /* identical to box height, or 222% */

    color: var(--font-4);
    /* padding-left: 23px; */
    margin-top: 24px;
    margin-bottom: 0px;
    /*position: absolute;
        left: 23px;
        top: 41px;*/
    position: relative;
  }

  .closeIcon {
    position: absolute;
    right: 36px;
    top: 3.5px;
  }

  .swipeTitle + * {
    margin-top: 3.91937290033595vh; /*35px;*/
  }

  .swipeContent {
    width: auto;
    height: auto;
    padding: 0px;
    background: var(--bg-4);
  }

  .smallSwipe {
    width: 48px;
    height: 45px;
    border-radius: 0px 20px 20px 0px;
    position: absolute;
    left: 0px;

    display: flex;
    justify-content: center;
    align-items: center;

    color: var(--white);
    transition: all 0.25s;

    cursor: pointer;
  }

  .smallTitle {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px; /*20px*/
    /* identical to box height, or 125% */
    letter-spacing: 0.2px;
  }

  .Ui,
  .Bi {
    background-color: #ffa910;
    bottom: 161px; /*18.02911534154535vh;*/
  }

  .Ci {
    background-color: var(--bg-5);
    bottom: 108px; /*12.09406494960806vh;*/
  }
}
