.pageControl {
    width: 100%;
    height: 55px;
    background-color: var(--main-bg);

    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0px;
}

.pageInfo {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    /* or 167% */

    color: #AB8DFF;

    margin-left: 36px;
}

.pageButtons {
    display:flex;
    justify-content: space-between;
    align-items: center;

    margin-right: 36px;
}

.pageButton {
    width: 34px;
    height: 34px;        

    background-color: #432C88;
    border-radius: 6px;
    cursor:pointer;

    display: flex;
    justify-content: center;
    align-items: center;
}

.topBorder {
    width: 100%;
    height: 0px;
    border-top: 1.6px solid #462D91;
    margin-top: 0px;
}