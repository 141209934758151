.container {
    width: 100vw;
    height:100vh;
    display: flex;
    font-family: "Poppins" !important;
}

.leftContainer {
    flex: 1 1 60.27777778vw;
    background-image: linear-gradient(#865ACE, #4800E2);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}

.leftContainer > img.plexyzIcon {
    position: absolute;
    left: 4.444444444vw; /*64px;*/
    top: 6.97674419vh;/*63px*/ 
}

.leftContainer > img.loginPic {
    padding-top: 21.59468439vh;
    width: 45.625vw;
    /*height: 36.65559247vh;*/
    /*width: 75.69124424%;*/
    height: 60vh;
}

.leftContainer > h2, .leftContainer > p {
    font-style: normal;
    color: #ffffff;
    text-align: center;
    width: 33.33333333vw;
}

.leftContainer > h2.title {
    font-size: 26px;
    font-weight: bold;
    line-height: 40px;/*4.42967885vh;*/
    padding: 2.67331118vh 4vw 0px 4vw;
}

.leftContainer > p.about {
    font-size: 16px;
    font-weight: 400px;
    line-height: 20px; /*2.21483942vh;*/
    padding-top: 2.67331118vh;
    width: 40vw;
}

.rightContainer {
    flex: 1 1 39.72222222vw;
    background-color: var(--main-bg);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.rightContainer > * {
    width: 28.40277778vw;
}

.rightContainer > h2.title {
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 40px;
    color: #ffffff;
    text-align: left;
    margin: 0px;
    padding:0px;
}

.rightContainer > .buttonContainer {
    display: flex;
    flex-direction:row;
    margin-top: 55px;
    /* justify-content: flex-start; */
}

.rightContainer button {
    width: 215px;
    height: 50px;
    background-color: #9603FF;
    color: #ffffff;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
}

.rightContainer button:hover {
    outline-color: transparent;
    outline-style:solid;
    box-shadow: 0 0 0 2px #9603FF;
}

.rightContainer button.buttonBack {
    width: 215px;
    height: 50px;
    background-color: var(--main-bg);
    color: #ffffff;
    border: 1px #ffffff solid;
    border-radius: 10px;
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
    margin-left: 15px;
}

.buttonBack:hover {
    box-shadow: 0 0 0 2px #fff;
}


.rightContainer > .inputContainer {
    margin: 0px;
    padding:0px;
}

.rightContainer .inputWithIcon {
    position: relative;
    margin: 0px;
    padding: 0px;
    width: 100%;
    height: auto;
}

.rightContainer .iconContainer {
    width: auto;
    margin: 0px;
    padding: 0px;
    height: auto;
    position: absolute;
    right:22px;
    bottom: 5.75px;
    cursor: pointer;
}

.rightContainer label {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: #C8BDE8;
    display: block;
    margin-bottom: 0px;
}

.rightContainer label:first-child {
    margin-top: 4vh;/*55px;*/
}

.rightContainer label:nth-child(2) {
    margin-top: 3.5vh;/*40px;*/
}

.rightContainer input {
    background: #1F1835;
    mix-blend-mode: normal;
    border: 2px solid #9603FF;
    box-sizing: border-box;
    border-radius: 12px;
    height: 40px;
    width: 100%;
    margin-top: 8px;
    padding-left: 20px;

    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #E9F3FF;
}

.rightContainer input::placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #AA86E6;
}

.rightContainer > p.forgotPassword {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    text-decoration-line: underline;
    color: #AA86E6;
    margin: 11px 0px 0px 15px;
    padding: 0px;
}

@media screen and (min-width:1440px) {
    .leftContainer > svg:nth-child(2) {
        
    }

    .rightContainer input, .rightContainer .iconContainer {
        height: 50px;
    }    

    .rightContainer .iconContainer {
        bottom: 21.5px;
        height: 22px;
    }
}

@media screen and (min-width:601px) and (max-width: 938px) {
    .leftContainer, .rightContainer {
        flex: 1 1 50vw;
    }

    .rightContainer > * {
        width: 80%;
    }
}

@media screen and (max-width: 600px) {
    .leftContainer {
        flex: 0 0 0vw;
        overflow: hidden;
    }

    .rightContainer {
        flex: 1 1 100vw;
    }

    .rightContainer > * {
        width: 85vw;
    }

    .rightContainer > h2.title {
        text-align: center;
    }

    .rightContainer > .buttonContainer {
        justify-content: center;
    }
}