.mainContainer {
    width: calc(100vw - 93px);/*100%;*/
    max-width: calc(100vw - 93px);
    min-height: 100vh;
    background-color: var(--dark);
    font-family: "Poppins";
    color: #ffffff;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 50px;

    /* padding-left: 160px; */
}


.container {
    /*width: 74.16081187%;/*950px;*/
    width: calc(100% - 321px);
    /* height: 536px; */
    background: var(--main-bg);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 15px;
    /*overflow: hidden;*/
    margin-bottom: 15px;
    margin-top: 15px;
    /* padding-bottom: 15px; */
}

.container > *, .container > .topBar > * {
    margin-bottom: 0px;
}

.container > .topBar {
    width: 96.52631579%;/*917px;*/
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: auto;
    margin-top: 15px;
    margin-bottom: 15px;
}

.title {
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height, or 91% */
    letter-spacing: 0.2px;
    color: var(--font-2);

    position: relative;
}


.logoutButton {
    /* width: 222px;
    height: 66px; */

    background: linear-gradient(152.91deg, #865ACE 3.01%, #4800E2 100%);
    border-radius: 12px;

    margin-top: 20px;
    margin-bottom: 20px;
    margin-right: 160px;
    cursor: pointer;

    /* transition: all 0.25s; */
    /* pointer-events: none; */

    display: flex;
    flex-direction: column;
    justify-content: center; 
    align-self: flex-end;

    padding: 15px 30px 15px 30px;
    /* opacity: 0; */
}

.logoutText {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 13px;

    color: var(--white);
}

.collapsible {
    color:var(--font-2);
    margin-left: 15px;
    display: flex;
    flex-direction: row;
    cursor: pointer;
}

.chevronContainer {
    /* position: absolute; */
    width: auto;
    height: auto;
    /* left: calc(100% + 5px); */
    margin-left: 15px;
    top: 0px;
    cursor: pointer;
    transition: all 0.25s;
    
}

.sortAsc {
    transform: rotate(180deg);
}


.outerBox {
    margin:10px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    align-self: flex-start;
    flex-wrap: wrap;
}

.box {
    border: 1px solid var(--white);
    border-radius: 10px;
    margin: 8px;
    width: 30%;
    
    /* padding:10px; */
}
.topBox {
    background-color: var(--bg-2);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 10px;
}
.divider {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 0.5px solid rgba(248, 242, 242, 0.926);
}
.box p {
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 11px;
    letter-spacing: 0.2px;
    color: var(--font-3);
    padding:15px
}

@media screen and (min-width:601px) and (max-width: 860px) {
    .container {
        /* width: calc(50% - 10px); */
        align-items: center;
    }
    .boxContainer {
        width: calc(100% - 70px);
    }
    .logoutButton {
        margin-right: 35px;
    }
}

@media screen and (max-width:600px) {
    .container {
        min-width: 100vw;
        /* min-height: calc(100vh - 143.79px); */
        justify-content: center;
        margin-top: 0px;
        min-height: auto;
    }
    .topTitle {
        position: static;
        width: 100%;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 40px;
        /* identical to box height, or 222% */
        padding-left: 22px;
        padding-top: 24px;
        padding-bottom: 9px;

        margin-bottom: 0px;
    }
    .boxContainer {
        width: 90vw;
    }
    .logoutButton {
        margin-right: 20px;
        margin-bottom: 20px;
    }
    
    .devTable {
        width: auto;
        /* max-height: auto; */
    }

    /* .devTable, .devTable > thead > .tableHeader, .devTable > tr, .devTable td {
        height: 40px;
    } */

    .devTable > thead > .tableHeader > th, .sortableColumn {
        font-size: 14px;
    }

    .devTable > thead > .tableHeader > th:first-child, .devTable > thead > .tableHeader > th:last-child {
        border-radius: 0px;
    }
    .devTable > thead > .tableHeader > th:first-child {
        padding-left: 15px;
    }
    .devTable > tbody > tr > td:first-child {
        padding-left: 15px;
    }

    .devTable td {
        border: none;
    }

}


