.sortSelection {
    width: 190px;
    max-width: 190px;
    height: 45px;

    background: rgba(134, 90, 206, 0.8);
    border: none;
    border-radius: 10px;
    padding-left: 19px;
    padding-right: 20px;

    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    /* or 125% */

    color: #C8BDE8;
    position: relative;

    display: flex;
    justify-content: space-around;
    align-items: center;

    cursor: pointer;
}

.chevronContainer {
    width: auto;
    height: auto;
    transition: all 0.25s;
    margin-right: 10px;
}

.selectOpen {
    transform: rotate(180deg);
}

.sortSelection > .options {
    position: absolute;
    left: 0px;
    bottom: 58px;

    width: 190px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    
}

.option {
    height: 40px;
    width: 190px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #C8BDE8;
    background-color: #2D203E;
    border: none;

    display:flex;
    justify-content: flex-start;
    align-items: center;

    padding-left: 29px;
}

.option > * {
    margin-left: 29px;
}

.option:hover {
    color: #ffffff;
    background-color: #865ACE;

}

@media screen and (max-width: 600px){
    .sortSelection {
        width: 170px;
        height: 40px;
        border-radius: 10px;

        font-size: 14px;
    }

    .option {
        height: auto;
        font-size: 8px;
        padding: 0px;
    }
}