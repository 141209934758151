.container {
    font-family: 'Poppins';
    width: calc(44.22471850944947% - 7.5px);/*38.05555555555556vw;/*548px;*/
    max-width: calc(44.22471850944947% - 7.5px);/*38.05555555555556vw;*/
    height: 414px;

    background: var(--main-bg);
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.container > * {
    margin-bottom: 0px;
    width: 88.5036496350365%/*485px;*/
}

.title {
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 20px;
    /* identical to box height, or 91% */

    text-align: left;
    letter-spacing: 0.2px;

    color: var(--font-2);

    margin-top: 47px;
}

.about {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    color: #AB8DFF;

    margin-top: 16px;
}

.content {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    /* or 167% */

    color: var(--font-2);
}

.visitorVis {
    height: calc(117px + 1.6px);/*additional 1.6px for top border */
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    border-top: 1.6px solid var(--bg-3);
}

.visitorVis:first-of-type {
    margin-top: 22px;
}

.visitorVis > .visContainer {
    flex: 1 1 67.62886598%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.visContainer > * {
    width: 89.63414634%;
    margin-left: 8px;
    margin-bottom: 0px;
}

.time {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */
    color: var(--font-1);
    margin-top: 20px;
}

.visContainer > .vis {
    height: 20px;
    margin-top: 14px;

    display: flex;
    justify-content: flex-start;
    align-items: stretch;
}

.barContainer {
    height: 11.68px;
    width: 231px;

    background: rgba(190, 208, 229, 0.15);
    border-radius: 10.5px;
    overflow: hidden;

    margin-top: 4.16px;
}

.bar {
    height: 11.68px;
    border-radius: 10.5px;
}

.blueBar {
    background: linear-gradient(#1CC2EB 14.06%, #00A3FF 89.06%);
}

.yellowBar {
    background: linear-gradient(#FFAC17 14.06%, #F17F00 89.06%);
}

.percentage {
    font-weight: 700;
    font-size: 22px;
    line-height: 20px;
    /* identical to box height, or 91% */

    text-align: right;
    letter-spacing: 0.2px;

    color: #D1BCFF;
    margin-left: 15px;
}

.visContainer > p.content {
    margin-top: 4px;
}

.visitorVis > .legendContainer {
    flex: 1 1 32.37113402%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.dotLegend {
    height: 30px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.dotLegend > p {
    display: flex;
    align-items: center;
    margin-left: 13px;
    margin-top: 10px;
}

.dot {
    width: 21px;
    height: 21px;
    left: 1220px;
    top: 1687px;
    border-radius: 10.5px;
}

.blueDot {
    background: #5C0FFF;
}

.yellowDot {
    background: #FFAC17;  
}

.whiteDot {
    background: var(--font-2);
}

@media screen and (min-width: 601px) and (max-width:1190px) {
    .visContainer > * {
        width: 100%;
        margin-left: 0px;
    }

    .barContainer {
        width: calc(100% - 63px);/*78.57142857142857%;*/
    }

    .percentage {
        width: 63px;/*48px + 15px 21.42857142857143%;*/
        margin-left: 0px;
        text-align: center;
    }
}

@media screen and (max-width: 600px) {
    .container {
        width: 100vw;
        max-width: 100vw;
        height: 314px;
        border-radius: 0px;
        margin-top: 10px;
    }

    .container > * {
        width: 82.36714975845411vw;/*273 + 25 + 43 = 341*/
    }

    .title {        
        font-weight: 600;
        font-size: 16px;

        margin-top: 22px;
    }

    .about {
        font-size: 12px;
        margin-top: 12px;
    }

    .visitorVis {
        margin-top: 0px;
        height: fit-content;
        border-top: none;
    }

    .visitorVis:first-of-type {        
        margin-top: 18px;
        border-bottom: 1.6px solid var(--bg-3);
    }

    .visitorVis:last-of-type {
        margin-top: 20px;
    }

    .visitorVis > .visContainer {
        flex: 1 1 auto;
    }

    .time {
        font-weight: 600;
        font-size: 14px;
        margin-top: 0px;
    }

    .visContainer > .vis {
        height: auto;
        padding: 0px;
    }

    .visitorVis > .legendContainer {
        flex: 1 1 0;
        width: 0px;
        height: 0px;
        overflow: hidden;
    }

    .barContainer {
        margin-top: 4.16px;
        width: 65.94202898550725vw;/*273px to 414px screen wide*/
    }
    
    .percentage {
        font-weight: 600;
        font-size: 20px;
        
        margin-left: 6.03864734299517vw;/*25px;*/
        margin-bottom: 0px;
    }

    .visContainer > p.content { 
        line-height: 20px;   
        margin-top: 4px;
        margin-bottom: 25px;
    }
}