.mainContainer {
  width: calc(100vw - 93px);
  max-width: calc(100vw - 93px);
  min-height: 100vh;
  background-color: var(--dark);
  font-family: "Poppins";
  color: #ffffff;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 50px;
}

.card {
  flex: 1;
  max-width: calc(25% - 20px);
  height: 200px; /* Adjust the height as needed */
  background: var(--main-bg);
  border-radius: 15px;
  margin: 20px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.card > .topBar {
  width: 100%;
  padding: 10px;
}

.card > .title {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  color: #ece6ff;
  padding: 10px;
}

.card > .tableContainer {
  flex: 1;
  padding: 30px;
  size: 50px;
  font-size: 20px;

  /* You can add more specific styles for the content within the card */
}

/* Your existing styles... */

/* Remove .tableContainerRow and .tableContainer styles */
