.container { 
    width: 303px;
    height: 317px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    
    margin: 0px;
}

.container > * {
    margin-bottom: 0px;
}

.container > .title { 
    width: 181px;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height, or 120% */

    text-align: center;
    color: var(--white);

    margin-top: 164px;
}

.container > .about {
    width: 252px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* or 143% */

    text-align: center;
    color: var(--font-2);

    margin-top: 8px;
}


.container > .upgradeButton {
    width: 132.33px;
    height: 36px;
    background-color: var(--white);
    border-radius: 7px;
    border: none;

    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    /*line-height: 20px;
     identical to box height, or 167% */

    text-align: center;
    color: #9603FF;

    margin-top: 15px;
}

@media screen and (min-width:601px) and (max-width: 860px) {
    .container {
        width: 100%;
    }
}

@media screen and (max-width: 600px) {
    .container {
        width: 78.98550724637681vw;/*327px*/
        min-height: 114px;
        height: fit-content;
        border-radius: 12px;
        align-items: flex-start;
        background: linear-gradient(152.91deg, #865ACE 3.01%, #4800E2 100%);
        position: relative;

        margin-top: 24px;
        margin-bottom: 21px;
    }    

    .container > *:not(.backgroundImage) {
        margin-left: 40.57971014492754vw;/*168px;*/
    }

    .container > .title {
        width: 235.02415458937198vw;
        font-size: 12px;
        line-height: 12px;
        /* identical to box height, or 200% */
        text-align: left;
        color: var(--white);

        margin-top: 23px;
    }

    .container > .about {
        width: 35.02415458937198vw;
        font-size: 9px;
        line-height: 14px;
        /* or 156% */
        text-align: left;

        color: var(--font-1);
        margin-top: 6px;;
    }

    .container > .upgradeButton {
        width: 72px;
        height: 24px;
        border-radius: 4px;

        font-size: 8px;
        line-height: 20px;
        /* identical to box height, or 250% */
        margin-top: 8px;
        margin-bottom: 17px;
    }

    .backgroundImage {
        width: 37.19806763285024vw;/*154px*/
        height: 93px;

        position: absolute;
        left: 0px;
        bottom: 0px;
        pointer-events: none;
    }
}