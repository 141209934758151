.container {
    width: calc(100vw - 93px);/*100%;*/
    max-width: calc(100vw - 93px);
    min-height: 100vh;
    background-color: var(--dark);
    font-family: "Poppins";
    color: var(--white);

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 50px;

}

.container > h3 {
    margin-top: 20px;
    text-align: center;
    color:var(--white);
}

.addDeviceDialog {
    width: fit-content;/*100%;    */
    height: auto;

    border-radius: 14px;

    padding: 20px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background: var(--bg-1);
}

.addDeviceTitle {
    color: var(--white);
    text-align: center;
    font-size: 17px;
}

.addDeviceSelect {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 15px;
}


.addDeviceLabel {
    color:var(--white);
    margin-right: 5px;
    text-align: left;
    width: 100%;
    margin-bottom: 5px;
}


.addDeviceButton {
    width: 48%;
    height: 35px;
    padding: 15px;

    border-radius: 8px;
    border: none;

    font-weight: 400;
    font-size: 16px;
    line-height: 12px;
    /* identical to box height */

    display: flex;
    justify-content: center;
    align-items: center;
    
    text-align: center;
    color: var(--font-2);
    cursor: pointer;
    

    transition: all .25s;
    min-width: 90px;

    margin-top: 10px;
    margin-bottom: 10px;
}

.addDeviceOk {
    margin-right: 2.5px;
    border: 1px solid #9603FF;
}

.addDeviceOk:hover, .addDeviceFull:hover {
    background-color: #9603FF;
}

.addDeviceCancel {
    margin-left: 2.5px;
    border: 1px solid red;
}

.addDeviceCancel:hover {
    background-color: red;
}

.addDeviceFull {
    border: 1px solid #9603FF;
    width: 100%;
    align-self: center;
}

.details {
    justify-content: flex-start;
    color: var(--white);
}

.allButtons {
    width: 100%;
    padding-left: 36px;
    margin-top: 14px;
    align-items: center;
}

.basicButton {
    width: auto;
    /* height: 50px; */
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 10px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-left: 10px;
}


.active {
    background: linear-gradient(152.91deg, #865ACE 3.01%, #4800E2 100%);
    font-weight: 600 !important;
    color: var(--white) !important;
}

.buttonTitle {
    text-align: center;
    font-weight: 400;
    font-size: 12px;
    margin-top: 10px;
    /* border: 1px solid var(--dark); */
}

.inputLabel {
    color:var(--white);
    margin-right: 5px;
    text-align: left;
    width: 100%;
    margin-bottom: 5px;
    font-size: 14px;
}


.inputContainer {
    margin-bottom: 10px;
    width:50vh;

}

.inputBox {
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    font-size: 14px;
    line-height: 14px;
}

.topTitle {
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 22px;
    color: var(--font-2);
    margin: 0px;
    margin-top: 20px;
    margin-bottom: 20px;
    /* position: absolute;
    left: 0px;
    top: 32px; */
}
.bindButton {
    background: linear-gradient(152.91deg, #865ACE 3.01%, #4800E2 100%);
    border-radius: 12px;

    margin: 15px 0px 20px 160px ;
    cursor: pointer;

    display: flex;
    flex-direction: column;
    justify-content: center; 
    align-self: flex-start;

    padding: 10px 30px 10px 30px;
    
}

.bindText {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 4px;

    color: var(--white);
}

.longModalContainer {
    overflow-y: auto;
    height: 86vh;
}

.longModal {
    position: relative;
    height: 72vh;
    overflow-y: auto;
    border-radius: 14px;
    padding: 20px;
    justify-content: center;
    align-items: center;

    background: var(--bg-1);
}

@media screen and (max-width:600px) {
    .container {
        min-width: 100vw;
        height:100%;
        /* min-height: calc(100vh - 143.79px); */
        justify-content: center;
        margin-top: 0px;
    }
    .basicButton {
        margin-right: 15px;
    }
    .addDeviceDialog {
        width: 90vw;
    }

}

@media screen and (min-width:601px) and (max-width: 860px) {
    .container {
        /* width: calc(100% - 70px) */
        /* width: 80vw; */
        align-self: center;
    }

    .bindButton {
        margin-right: 40px;
        margin-left: 0px;
        align-self: flex-end;
    }
}







