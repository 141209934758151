.container {
    width: calc(55.81947743467933% - 7.5px);/*48.95833333333333vw;/*705px;*/
    max-width: calc(55.81947743467933% - 7.5px);/*48.95833333333333vw;*/
    height: 414px;

    background: var(--main-bg);
    border-radius: 15px;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;/*this is different with figma, which actually have different left and right padding...*/

}

.container > * {
    width: 89.78723404%;/*633px;*/
    margin-bottom: 0px;
}

.topBar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    margin-top: 29px;
}

.title {
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 20px;
    /* identical to box height, or 91% */

    text-align: left;
    letter-spacing: 0.2px;

    color: var(--font-2);
}

.optionsRow {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    margin-top: 19px;
}

.options {
    width: 204px;
    height: 20px;

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.option {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */

    margin: 0px;
    padding: 0px;
    text-align: right;
    letter-spacing: 0.2px;

    color: var(--font-5);

    cursor: pointer;
}

.active {
    color: var(--white);
}

.lineLegends {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 11px;
}

.lineLegend {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
}

.lineLegend:not(:first-child){
    margin-left: 63px;
}

.dot {
    width: 19px;
    height: 19px;
    border-radius: 10px;
}

.blueDot {
    background-color: var(--blue-dot);
}

.yellowDot {
    background-color: var(--yellow-dot);
}

.legendLabel {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    margin: 0px 0px 0px 8px;
    padding: 0px;
    /* identical to box height, or 167% */

    color: var(--font-5);
}

.legendLabelActive {
    color: var(--font-1);
}

.graphContainer {
    /*width: 633px;/*WARNING : this is hack! should be looked into later!*/
    width: 89.78723404255319%;
    height: 185px;
    /* the line chart should have 57px - 39px = 18px left margin*/
    margin-top: 39px;
}

@media screen and (max-width: 600px) {
    .container {
        width: 100%;
        max-width: 100%;
        height: 456px;
        border-radius: 0px;
        margin-top: 10px;
    }

    .topBar {
        margin-top: 22px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 82.36714975845411vw;/*341px*/
    }

    .title {
        font-weight: 600;
        font-size: 16px;
        margin-bottom: 24px;
    }

    .optionsRow {
        justify-content: center;
        margin-top: 28px;
        margin-bottom: 0px;
        width: 45.16908212560386vw;/*187px*/
    }

    .options {
        width: 100%;
    }

    .option {
        font-weight: 600;
        font-size: 14px;
    }

    .lineLegends {
        margin-top: 12px;
        width: 82.36714975845411vw;
    }

    .lineLegend:not(:first-child){
        margin-left: 6.28019323671498vw;/*26px*/
    }

    .legendLabel {
        font-weight: 400;

        line-height: 20px;
        /* identical to box height, or 167% */
        color: var(--font-1);
    }

    .graphContainer {
        width: 82.36714975845411vw;
        margin-top: 44px;
        margin-bottom: 0px;
    }
}