.barchartAndYAxis {
    width: 100%;
    height: 89.75609756%;/*184px / 205px*/

    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    
}

.yAxis {
    flex: 1 1 20.2970267%;/*41px / 202px*/
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;;
}

.yAxisLabel {
    font-style: normal;
    font-weight: 400;
    font-size: 13.9755px;
    line-height: 13.9755px;
    /* identical to box height, or 150% */

    text-align: right;

    color: #C8BDE8;
    padding-right: 6px;
}

.barchart {
    flex: 1 1 79.7029703%;/*161px / 202px */
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    border-bottom: 2px solid #AA86E5;
}

.barShade {
    width: 20px;
    height: calc(100% - 5px);
    background-color: rgba(236, 229,255, 0.2);
    border-radius: 5px;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}

.bar {
    width: 20px;
    border-radius: 5px;
    transition: all 0.25s;
}

.xAxisContainer {
    width: 100%;
    height: 10.24390244%;/* 21px / 205px */
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.xAxis {
    width: 79.7029703%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.xAxisLabel {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    /* or 167% */

    text-align: center;

    color: #ECE6FF;

    margin: 0px;
    padding:0px;
}

@media screen and (max-width: 600px) {
    .yAxisLabel {
        line-height: 21px;
    }

    .barchart {
        border-left: 2px solid #AA86E5;
    }

    .xAxisLabel {
        line-height: 20px;
    }
}