.sortSelection {
    width:50vh;
    /* max-width: 190px; */
    /* height: 52px; */

    /* background: rgba(134, 90, 206, 0.8); */
    background: var(--white);
    padding: 10px;
    border-radius: 10px;   

    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    /* or 125% */

    color: #000;
    position: relative;

    display: flex;
    justify-content: space-between;
    align-items: center;

    cursor: pointer;
    
}

.chevronContainer {
    width: auto;
    height: auto;
    transition: all 0.25s;
}

.selectOpen {
    transform: rotate(180deg);
}

.sortSelection > .options {
    position: absolute;
    left: 0px;
    top: 46px;
    
    width:50vh;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    z-index: 3000;
    background-color: var(--white);
    
}

.option {
    /* height: 40px; */
    width:50vh;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #000;
    background-color: var(--white);
    border: none;

    display:flex;
    justify-content: flex-start;
    align-items: center;

    padding: 10px;
   
}

.option > * {
    margin-left: 29px;
}

.option:hover {
    color: #000;
    background: rgba(255, 172, 23, 0.5);

}

@media screen and (max-width: 600px){
    .sortSelection {
        width: 37vh;
        height: 40px;
        border-radius: 10px;

        font-size: 14px;
    }

    .option {
        height: auto;
        font-size: 8px;
        padding: 0px;
    }
    
}