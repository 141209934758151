/*
    w3schools tooltip sample :
    https://www.w3schools.com/howto/howto_css_tooltip.asp
    https://www.w3schools.com/howto/tryit.asp?filename=tryhow_css_tooltip
*/
.tooltip {
    position: relative;
    /*display: inline-block;
    border-bottom: 1px dotted black;*/
}
  
.tooltip .tooltiptext {
    /*visibility: hidden;*/
    pointer-events: none;
    display: none;
    width: 120px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: 10%;
    right: 125%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
}
  
.tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 100%; /* To the right of the tooltip */
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent #555;
}
  
.tooltip:hover .tooltiptext {
    display: inline;
    /*visibility: visible;*/
    opacity: 1;
}