.container {
    width: 303px;
    height: 199px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: var(--main-bg);
}

.container > * {
    margin-bottom: 0px;
}

.container > .title {
    width: 243px;
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 20px;
    /* identical to box height, or 91% */

    display: flex;
    align-items: center;
    letter-spacing: 0.2px;

    color: var(--font-2);

    margin-top: 47px;
}

.infoCells {
    display: flex;
    justify-content: flex-start;
    align-items: center; 
    width: 241px; 
    margin-top: 15px;
}

.infoCell {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: auto;
    width: 50%;
}
  
.infoTitle {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
      /*line-height: 20px;
      /* identical to box height, or 167% */
  
    text-transform: uppercase;
    line-height: 12px;
    color: var(--font-5);
}
  
.infoContent {
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 26px;
    /* identical to box height, or 154% */

    color: var(--font-1);
    padding:0px;
    margin:0px;
}

.barContainer {
    width: 241px;
    height: 12px;
    background-color: rgba(255, 255, 255, 0.5);
    border: 1px solid #6149A8;
    border-radius: 10px;
    display: flex;
    justify-content: flex-start;
    overflow: hidden;

    margin-top: 11px;
}

.barContainer > .bar {
    height: 12px;
    background: linear-gradient(90.1deg, #FFAC17 16.78%, #F17F00 89.97%);
    border-radius: 10px;
    
}

@media screen and (min-width:601px) and (max-width: 860px) {
    .container {
        width: 100%;
    }

    .container > .title {
        margin-top: 25px;
        width: 80.1980198019802%;
    }

    .infoCells, .barContainer {
        width: 79.53795379537954%;
    }
}

@media screen and (max-width: 600px) {
    .container {
        width: 100%;
        height: 197px;
        border-radius: 0px;
        margin-top: 10px;
    }

    .container > * {
        width: 82.60869565217391vw;/*342px*/
    }

    .container > .title {
        width: 82.60869565217391vw;
        font-weight: 600;
        font-size: 16px;
        margin-top: 21px;
    }

    .infoCells {
        margin-top: 26px;
    }

    .infoCell {
        width: auto;
    }

    .infoCell:last-of-type {
        margin-left: 14.49275362318841vw;
    }

    .infoTitle {
        line-height: 12px;
    }

    .infoContent {
        font-size: 20px;
        line-height: 40px;
    }
}