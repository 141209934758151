.mainContainer {
  width: calc(100vw - 93px); /*100%;*/
  max-width: calc(100vw - 93px);
  min-height: 100vh;
  background-color: var(--dark);
  font-family: "Poppins";
  color: #ffffff;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /*align-items: center;*/
  margin-top: 50px;
}

/* .container {
  width: calc(100% - 250px);
  height: 536px;
  background: var(--main-bg);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 15px;
  padding: 20px;
  overflow: hidden;
  margin-bottom: 20px;
  margin-top: 22px;
} */

.container > *,
.container > .topBar > * {
  margin-bottom: 5px;
}

.container > .topBar {
  width: 96.52631579%; /*917px;*/

  /* display: flex;
      justify-content: space-between;
      align-items: center;
      height: auto;
      margin-top: 27px; */
}

.tableCard,
.deleteCard {
  background-color: var(--main-bg);
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.tableCard {
  margin: 50px 20px 50px 20px;
}

.deleteCard {
  padding: auto 20px auto 20px;
}

.usersTitle,
.userTitle {
  font-style: normal;
  font-weight: 800;
  font-size: 17px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: var(--font-3);

  margin: 20px auto 15px 15px;
}

.userProp {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: var(--font-3);

  margin: 5px 20px 5px 20px;
}

.dialogBtnRow {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--bg-1);

  height: 55px;

  margin-top: 20px;
}

.dialogBtn {
  height: 35px;

  background: linear-gradient(152.91deg, #865ace 3.01%, #4800e2 100%);
  border: none;
  border-radius: 8px;

  display: flex;
  justify-content: center;
  align-items: center;

  padding-left: 20px;
  padding-right: 20px;
}

.dialogLbl {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;

  margin: 0px;

  color: var(--white);
}

.tableContainer {
  width: 96.52631579%; /*auto;*/
  /* height: 437px; */
  margin-top: 20px;
  position: relative;
  overflow: hidden;
}

.devTable {
  width: 100%; /*917px;*/
  border-collapse: collapse;
  max-height: 437px;
  margin-top: 0px;
  overflow: hidden;
}

.devTable,
.devTable > thead > .tableHeader,
.devTable > tr,
.devTable td {
  border: none;
  text-align: center;
}

.devTable > thead > .tableHeader {
  height: 49px;
  pointer-events: all !important;
}

.devTable > thead > .tableHeader > th,
.sortableColumn {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.2px;
  background-color: #432c88;
  color: #ece5ff;
  pointer-events: all !important;
}

.devTable > tbody {
  max-height: 100px;
  height: 100px;
  overflow-y: auto;
  overflow-x: hidden;
}

.devTable td {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #ece6ff;
  height: 41px;
  border-bottom: 1.6px solid #462d90;
}

.mainContainer > h3 {
  margin-top: 20px;
  text-align: center;
  color: #ffffff;
}

.topTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 22px;
  color: #c8bde8;
  margin: 0px;
  margin-top: 20px;
  margin-bottom: 20px;
  /* position: absolute;
      left: 0px;
      top: 32px; */
}

.title {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #ece6ff;
  /* height: 41px; */
}

.boxTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0.2px;
  color: #ab8dff;
}

.boxDescription {
  font-weight: 400;
  letter-spacing: 0.2px;
  font-size: 13px;
  color: #ece6ff;
  margin-top: 10px;
}

.inputContainer {
  /* width:50vh; */
  width: 100%;
  margin-top: 15px;
  /* border-bottom: 1.6px solid #462D90; */
  /* padding-bottom: 15px; */

  flex-grow: 1;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-right: 1em;
  flex-direction: column;
  align-items: baseline;
  align-content: flex-start; /* NEW */
}
.inputLabel {
  color: #c8bde8;
  margin-right: 5px;
  text-align: left;
  /* width: 20%; */
  /* margin-bottom: 5px; */
  font-size: 12px;
  font-weight: normal;
  /* text-transform: uppercase; */
}

.inputBox {
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  font-size: 14px;
  line-height: 16px;
  color: #000;
}
.inputDisabled {
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  font-size: 14px;
  line-height: 16px;
  background-color: gray;
  color: #000;
}

.editButton {
  background: linear-gradient(152.91deg, #865ace 3.01%, #4800e2 100%);
  border-radius: 12px;

  margin-top: 15px;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: flex-end;
  padding: 15px 30px 15px 30px;
}

.editText {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  /* margin-bottom: 4px; */

  color: #ffffff;
}

@media screen and (min-width: 601px) and (max-width: 860px) {
  .container {
    width: calc(100% - 70px);
    margin-top: 20px;
  }
}

@media screen and (min-width: 601px) and (max-width: 800px) {
  .container > .topBar {
    flex-direction: column;
    align-items: flex-start;
  }

  .topTitle {
    padding-bottom: 10px;
  }

  .tableContainer,
  .devTable {
    max-height: 375px;
  }
  .title {
    padding-bottom: 10px;
  }
}

@media screen and (max-width: 600px) {
  .container {
    width: 90vw;
    /* min-height: 587px; */
    margin: 10px 0px 0px;
    padding: 15px;
    border-radius: 10px;
  }

  /* .container > *, .container > .topBar {
          width: 100%;
      } */

  .topBar {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    /* margin-top: 22px; */
  }

  .mainContainer {
    min-width: 100vw;
    min-height: calc(100vh - 143.79px);
    justify-content: center;
    margin-top: 0px;
  }
  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */

    letter-spacing: 0.2px;
    width: 100%;
    padding-left: 36px;
  }

  .inputContainer {
    flex-grow: 0;
  }
}

/* Add the following style for a container to hold two tables in a row */
.tableContainerRow {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

/* Style for each table */
.tableContainerRow .devTable {
  width: 48%; /* Adjust the width as needed */
  border-radius: 15px;
  margin-bottom: 20px;
  overflow: hidden;
}

.basicButton {
  width: auto;
  /* height: 50px; */
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 10px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-left: 10px;
}

.active {
  background: linear-gradient(152.91deg, #865ace 3.01%, #4800e2 100%);
  font-weight: 600 !important;
  color: var(--white) !important;
}

.buttonTitle {
  text-align: center;
  font-weight: 400;
  font-size: 12px;
  margin-top: 10px;
  /* border: 1px solid var(--dark); */
}

/* reports.module.css */

.container {
  width: calc(100% - 321px);
  background: var(--main-bg);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center; /* Updated alignment to center */
  border-radius: 15px;
  padding: 20px;
  overflow: hidden;
  margin-bottom: 20px;
  margin-top: 22px;
}

.iframeContainer {
  margin: 0 auto;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

iframe {
  width: 100%;
  height: 100%;
  border: none;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
