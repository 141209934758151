.container {
    /*width: 70.625%;/*904px;*/
    width: calc(100% - 369px);
    height: 480px;

    background: var(--main-bg);
    border-radius: 15px;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.container > * {
    width: 92.92035398%;/*840px;*/
    margin-bottom: 0px;
}

.topBar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    margin-top: 31px;
}

.title {
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 20px;
    /* identical to box height, or 91% */

    letter-spacing: 0.2px;

    color: var(--font-2);
}

.legendRow {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;

    margin-top: 24px;
}

.legend {
    width: 130px;

    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.dot {
    width: 19px;
    height: 20px;
    border-radius: 10px;
}

.purpleDot {
    background: #5C0FFF;
}

.yellowDot {
    background: #FFAC17;
}

.whiteDot {
    background: var(--font-2);
}

.legendText{
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    height: 20px;
    /* identical to box height, or 167% */

    color: var(--font-1);

    margin-left: 8px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.demographicVisRow {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-top: 21px;
}

@media screen and (min-width:601px) and (max-width: 1024px) {
    .topBar {
        margin-top: 20px;
    }

    .legendRow {
        margin-top: 16px;
    }

    .demographicVisRow {
        flex-direction: column;
    }
}

@media screen and (min-width:601px) and (max-width: 860px) {
    .container {
        width: calc(50% - 10px);
    }

    .title {
        font-size: 16px;
    }
}

@media screen and (max-width: 600px) {
    .container {
        width: 100%;
        height: auto;
        border-radius: 0px;

        justify-content: flex-start;
        align-items: center;
    }
    
    .container > * {
        width: 89.3719806763285vw;
    }
    .topBar {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: 25px;
    }

    .title {        
        font-weight: 600;
        font-size: 16px;
        margin-bottom: 24px;
    }

    .legendRow {
        margin-top: 12px;
    }

    .legendText {        
        font-weight: 400;
    }

    .demographicVisRow {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 19px;
    }
}