.container {
  width: 390px;
  border-radius: 10px;
  background-color: var(--main-bg);
  font-family: "Poppins";
  white-space: nowrap;
  margin-top: 20px;
}

.bottomInfo > * {
  max-width: 332px;
  width: 332px;
}

.bottomInfo {
  background-color: var(--main-bg);
  font-family: "Poppins";
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-bottom: 22px;
  border-radius: 10px;
}

.bottomInfo > .topBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 22px;
}

.title {
  font-weight: bold;
  font-size: 17px;
  line-height: 17px;
  letter-spacing: 0.2px;
  color: var(--font-2);
}

.infoRow1 {
  display: flex;
  justify-content: space-between; /* Ensure space-between */
  align-items: center; /* Ensure vertical alignment */
  margin-bottom: 18px;
  margin-top: 5px;
  position: relative; /* Add relative positioning */
}

.row1Cell {
  width: 50%;
  height: auto;
}

.infoCell {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  text-overflow: ellipsis;
  margin-bottom: 8px;
}

.infoTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
  line-height: 12px;
  color: var(--font-5);
  margin: 0px auto 6px 0px;
  padding: 0px;
}

.infoContent {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: var(--font-1);
  line-height: 16px;
  margin: 0px auto 4px 0px;
  padding: 0px;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  text-overflow: ellipsis;
}

.container,
.container * {
  transition: all 0.25s;
}

.closed {
  width: 40px;
  border-radius: 0px 15px 15px 0px;
}

.closed *:not(.nextArrow) {
  opacity: 1;
}

.closed .nextArrow,
.closed .nextArrow * {
  opacity: 1;
}

.nextArrow {
  width: 100%;
  height: 18px;
  background-color: var(--bg-1);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  top: 0;
  transform: rotate(180deg);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.nextArrow > svg {
  margin-right: 15px;
  cursor: pointer;
}

.closed .nextArrow svg {
  transform: rotate(180deg);
}

/* Add this new class for the beacon image container */
.beaconImgContainer {
  margin-left: auto;
  padding-top: 20px; /* Lower the image by 20px */
  position: absolute; /* Position it absolutely */
  right: 0; /* Align it to the right */
}

.withImage {
  width: auto;
  height: 79.78px; /* Adjust height as needed */
}

@media screen and (max-width: 600px) {
  .container {
    width: 90%; /* Adjust as needed */
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .infoContent {
    text-align: left; /* Adjust as needed */
    font-size: 12px;
    line-height: 14px;
  }

  .infoTitle {
    font-size: 14px;
    line-height: 16px;
  }

  .nextArrow {
    display: none;
  }

  .infoRow1 {
    flex-direction: row;
    align-items: center;
    justify-content: space-between; /* Ensure space-between in mobile */
    margin-bottom: 10px;
    width: 100%;
    position: relative; /* Add relative positioning */
  }

  .beaconImgContainer {
    margin-left: 10px; /* Adjust margin as needed */
    padding-top: 20px; /* Lower the image by 20px in mobile as well */
    position: absolute; /* Position it absolutely */
    right: 0; /* Align it to the right */
  }

  .withImage {
    width: auto;
    height: 79.78px;
  }
}
