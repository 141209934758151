.topWrapper {
    width: auto;
    max-height: 100vh;
    position: relative;
    overflow-y: visible;
}

.layoutContainer {
    width: 100vw;
    max-width: 100vw;
    height: auto;
}

.topNavbar {
    width:100vw;
    height: 102px;

    background: #20173A;
    mix-blend-mode: normal;
}

.content {
    display: grid;
    grid-template-columns: 93px auto;

    position: relative;
    overflow: hidden;
}

.leftToolbar {
    width: 93px;
    /*height: 1861px;*/
    height: "100%";

    background: #1F1835;
}

.modalContainer {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100vw;
    height: 100vh -50px;
    overflow: hidden;
    pointer-events: none;
}

.modalContainer * {
    pointer-events: all;
}

@media screen and (max-width: 600px) {
    .topWrapper {
        width: 100vw;
        position: relative;
    }
    
    .layoutContainer {
        height: 100vh;
        overflow: hidden;
        position: relative;
    }

    .swipeContainer {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100vw;
        height: 100vh;
        height: auto;
        overflow: auto;
        pointer-events: none;
        background-color: red;
    }

    .swipeContainer * {
        pointer-events: all;
    }

    .content {
        grid-template-columns: 0px auto;
    }

    .leftToolbar {
        width: 0px;
        height: 0px;
    }
}