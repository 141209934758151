.container {
  position: fixed; /* Change from absolute to fixed */
  left: 0px;
  top: 0px;
  width: 93px;
  /* min-height: calc(100vh - 102px); */
  height: 100%;
  background: #1f1835;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: hidden;
  z-index: 998; /* Change this from 1000 to 998 */
  /* Remove duplicate position: fixed; */
}

.isOpen {
  width: 254px;
  height: 100%;
}

.container,
.container * {
  transition: all 0.25s;
}

.menuItem {
  width: 55px;
  height: 46px;

  margin-left: 23px;
  margin-bottom: 0px;
  margin-top: 3px;
  cursor: pointer;
  border-radius: 6px;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  overflow: hidden;

  position: relative;
  /*background-color: rgba(0, 0, 0, 0);*/
}

.menuItem:first-of-type {
  margin-top: 60px;
}

.menuItem::after,
.menuItem::before {
  background: none repeat scroll 0 0 transparent;

  content: "";
  display: block;

  position: absolute;
  background: #9603ff;
  transition: width 0.35s ease 0s;

  width: 0px;
  height: 4px;
}

.menuItem::before {
  left: 0;
  top: 0;
}

.menuItem::after {
  right: 0px;
  bottom: 0px;
}

.menuItem:hover::before,
.menuItem:hover::after {
  width: 100%;
}

.menuActive {
  background-color: #9603ff;
  pointer-events: none;
  cursor: none;
}

.isOpen .menuItem,
.menuItemOpen {
  width: 209px;
}

.menuIcon {
  margin-left: 16px;
}

.menuText {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px; /*21px;
    /* identical to box height */

  color: #ffffff;
  margin-left: 10.75px;
  opacity: 0;
  text-align: left;
  display: flex;
  align-items: center;

  transition: all 0s;
}

.isOpen .menuItem .menuText {
  opacity: 1;
  transition: all 0.25s;
  transition-delay: 0.25s;
}

.resourceButton {
  width: 222px;
  height: 66px;

  background: linear-gradient(152.91deg, #865ace 3.01%, #4800e2 100%);
  border-radius: 12px;
  position: absolute;
  bottom: 22px;
  /* margin-top: 404px; */
  margin-left: 22px;
  cursor: pointer;

  /* transition: all 0.25s; */
  pointer-events: none;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  padding-left: 19px;
  opacity: 0;
}

.resourceText {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px; /*24px;
    /* identical to box height 

    display: flex;
    align-items: center;*/
  margin-bottom: 4px;

  color: #ffffff;
}

.resourceLabel {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px; /*18px;
    /* identical to box height 

    display: flex;
    align-items: center;*/
  margin-top: 3.5px;

  color: #ffffff;
}

.isOpen .resourceButton {
  opacity: 1;
  pointer-events: all;
  transition-delay: 0.25s;
}

@media screen and (max-width: 600px) {
  .container {
    position: fixed;
    left: 0px;
    top: calc(100vh - 63.79px);
    width: 100%;
    /*height: calc(100% - 102px);*/
    min-height: 63.79px;
    height: 63.79px;
    max-height: 63.79px;

    background: #1f1835;
    cursor: pointer;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    padding-left: 47.5px;
    padding-right: 47.5px;

    overflow: hidden;
    z-index: 1000; /* Add this to ensure it's above other content on mobile too */
  }

  .resourceButton {
    display: none;
  }

  .menuItem,
  .menuItem:first-of-type {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0px;
  }

  .menuActive {
    background-color: rgba(0, 0, 0, 0);
  }

  .menuItem:hover::before,
  .menuItem:hover::after {
    width: 0;
  }

  .menuIcon {
    margin-left: 0px;
  }

  .menuText {
    display: none;
  }

  .hideAtMobile {
    /*display: none;*/
  }
}

.mainContent {
  margin-left: 93px; /* Width of the closed sidebar */
  transition: margin-left 0.25s;
}

.sidebarOpen .mainContent {
  margin-left: 254px; /* Width of the open sidebar */
}

@media screen and (max-width: 600px) {
  .mainContent {
    margin-left: 0;
    margin-bottom: 63.79px; /* Height of the mobile sidebar */
  }
}
