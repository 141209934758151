.tableContainer {        
    padding: 0px;    
    
    display: flex;
    flex-direction: column;
    margin: 0px 15px auto 15px;
}

.devTable {        
    border-collapse: collapse;        
    overflow: hidden;    
}

.devTable, .devTable > thead >.tableHeader, .devTable > tr, .devTable td {
    border: none;
}

.devTable > thead > .tableHeader {
    height: 40px;
}

.devTable > thead > .tableHeader > th {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.2px;
    background-color: var(--bg-2);
    color: var(--font-3);
}

.devTable > thead > .tableHeader > th:first-child {
    padding-left: 18px;
    border-radius: 10px 0 0 10px;
}

.devTable > thead > .tableHeader > th:last-child {
    width: 150px;
    max-width: 150px;
    border-radius: 0px 10px 10px 0px;
}

.devTable td {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: var(--font-1);
    height: 41px;
    border-bottom: 1.6px solid var(--bg-3);
}

.devTable > tbody > tr > td:first-child {
    padding-left: 18px;
}

.devTable .dataRow:hover, .hoveredMac {
    background: rgba(255, 172, 23, 0.5);
}

.action {
    height: 30px;

    background: linear-gradient(152.91deg, #865ACE 3.01%, #4800E2 100%);
    border: none;
    border-radius: 8px;

    display: flex;    
    justify-content: center;
    align-items: center;

    padding-left: 15px;
    padding-right: 15px;
}

.actionLabel {
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;

    margin: 0px;

    color: var(--white);
}

.actionCell {
    width: 150px;
    max-width: 150px;
    display: flex;
    align-items: center;

    height: 100%;
}

.actionIcon {
    width: 20px;
    height: 20px;
}

.noAction {
    cursor: none;
}

.editInput {
    color: var(--dark);
}

@media screen and (max-width: 600) {
    .tableContainer{
        width: 100%;
        /*height: auto;/*416px;*/
        margin-top: 15px;
    }

    .devTable{
        width: 100%;
    }

    .devTable > thead > .tableHeader {
        height: 40px;
    }
    
    .devTable > thead > .tableHeader > th {
        font-weight: 600;
        font-size: 14px;
    }

    .devTable > thead > .tableHeader > th:first-child {
        border-radius: 0px;
    }
    
    .devTable > thead > .tableHeader > th:last-child {
        border-radius: 0px;
    }

    .devTable td{
        color: var(--white);
        height: 36px;
        border-bottom: none;
    }
}