::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    /*box-shadow: inset 0 0 5px grey; */
    border-radius: 3.5px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    width: 7px;
    height: 7px;
    background: #9B85DA;
    border-radius: 3.5px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    
  }

.container {
    width: 390px;
    /* height: 484px; */
    max-height: 484px;
    border-radius: 15px;
    background-color: var(--main-bg);
    margin-top: 20px;
    
}

.container > * {
    margin-bottom: 0px;
}

.bottomInfo > * {
    margin-bottom: 0px;
}

.bottomInfo {
    font-family: "Poppins";
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    padding-bottom: 15px;
}

.bottomInfo > .title {
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    /* identical to box height, or 91% */
    letter-spacing: 0.2px;
    color: var(--font-2);
    background-color: var(--bg-1);
    padding-top: 18px;
    padding-bottom: 18px;
    /* padding-left: 23px; */
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 24px;
}

.bottomInfo > .about {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #AB8DFF;
    width: 338px;
    margin-top: 17px;
}

.chevronContainer {
    /* position: absolute; */
    width: auto;
    height: auto;
    /* left: 297px;
    top: 18px; */
    cursor: pointer;
    transition: all 0.25s;
    margin-left: 20px;
}

.sortAsc {
    transform: rotate(180deg);
}

.tableContainer {
    height: 287px;/*326px;*/    
    width: auto;
    margin-top: 13px;    
    padding: 0px;    
}

.devTable {
    width: 348px;
    border-collapse: collapse;    
    max-height: 326px;
    margin-top: 0px;
    margin-bottom: 0px;
    overflow: hidden;    
}

.devTable, .devTable > thead >.tableHeader, .devTable > tr, .devTable td {
    border: none;
}

.devTable > thead > .tableHeader {
    height: 40px;
}

.devTable > thead > .tableHeader > th {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.2px;
    background-color: var(--bg-2);
    color: var(--font-3);
}

.devTable > thead > .tableHeader > th:first-child {
    padding-left: 18px;
    border-radius: 10px 0 0 10px;
}

.devTable > thead > .tableHeader > th:last-child {
    border-radius: 0px 10px 10px 0px;
}

.devTable td {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: var(--font-1);
    height: 41px;
    border-bottom: 1.6px solid var(--bg-3);
}

.devTable > tbody > tr > td:first-child {
    padding-left: 18px;
}

.devTable .dataRow:hover, .hoveredMac {
    background: rgba(255, 172, 23, 0.5);
}

.deviceDot {
    width: 12px;
    height: 12px;
    border-radius: 6px;
    border: none;
    margin-left: 15px;
}

.devTable .dataRow:hover .deviceDot {
    border: 1.4px solid #D5C6FF;
}

.inputContainer {
    margin-left: 15px;
    display: flex;
    flex-direction: row;
    width: 50%;
    border-width: 1px solid var(--white);
}

.input{
    display: none;
    padding:4px;
    backdrop-filter: blur(4px);
    border-radius: 8px;
    border: 1px solid;
    border-image-source: linear-gradient(180deg, rgba(255, 255, 255, 0.161) 0%, rgba(255, 255, 255, 0) 100%);
    margin-right: 8px;
    
}
.input:focus {
    color:'#000';
}

.searchIcon {
    cursor: pointer;
}

.closedSearch{
    display: block;
    max-width:60%;
}

/*collapsed */
.container, .container * {
    transition:all 0.25s;
}

.closed {
    width: 40px;
    border-radius: 0px 15px 15px 0px;
}

.closed *:not(.nextArrow) {
    opacity: 0;
}

.closed .nextArrow, .closed .nextArrow * {
    opacity: 1;
}

.nextArrow {
    width: 100%;
    height: 18px;
    background-color: var(--bg-1);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    top:0;
    transform: rotate(180deg);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.nextArrow > svg {
    margin-right: 15px;
    cursor: pointer;
}

.closed .nextArrow svg {
    transform: rotate(180deg);
}


@media screen and (max-width:600px) {
    .container {
        width: 100%;
        height: 600px;
        max-height: fit-content;
        border-radius: 0px;
        margin-top: 0px;
    }

    .container * > {
        margin-top: 0px;
    }

    .container > .title {
        width: auto;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        /* identical to box height, or 125% */
        letter-spacing: 0.2px;
        padding: 0px;
        margin: 19px auto 0px 10.8695652173913vw;/*45px*/
        background-color: inherit;
    }

    .chevronContainer {
        left: calc(100% + 5px);
        top: 0px;
    }

    .container > .about {   
        width: 78.5024154589372vw;     
        font-size: 12px;
        color: #9B85DA;
        margin-top: 26px;
    }

    .tableContainer{
        width: 100%;
        /*height: auto;/*416px;*/
        margin-top: 15px;
    }

    .devTable{
        width: 100%;
    }

    .devTable > thead > .tableHeader {
        height: 40px;
    }
    
    .devTable > thead > .tableHeader > th {
        font-weight: 600;
        font-size: 14px;
    }

    .devTable > thead > .tableHeader > th:first-child {
        border-radius: 0px;
    }
    
    .devTable > thead > .tableHeader > th:last-child {
        border-radius: 0px;
    }

    .devTable td {
        color: var(--white);
        height: 36px;
        border-bottom: none;
    }

}