.topToolbar {
  padding-top: 30px;
  margin-bottom: 21px;
  width: 89.16666667vw;
  display: flex;
  flex-direction: column; /* Ensure vertical stacking of elements */
  justify-content: center;
  align-items: center;
  position: relative;
  flex-wrap: wrap;
}

.disclaimer {
  margin-top: 20px;
  font-size: 14px;
  color: var(--font-2);
  text-align: center; /* Center align text */
  width: 100%;
}

.disclaimer p {
  max-width: 80%; /* Ensures the text does not span too wide */
  margin: 0 auto; /* Centers the text block */
  text-align: justify; /* Justify text for better readability */
}

@media screen and (max-width: 600px) {
  .topToolbar {
    width: 100vw;
    padding: 0px;
  }

  .disclaimer {
    margin-top: 10px;
    font-size: 12px;
  }

  .disclaimer p {
    max-width: 90%; /* Adjust for smaller screens */
  }
}
