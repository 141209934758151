.container {
    /*width: 74.16081187%;/*950px;*/
    width: calc(100% - 321px);
    /* height: 536px; */
    background: var(--main-bg);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 15px;
    /*overflow: hidden;*/
    margin-bottom: 15px;
    /* margin-top: 15px; */
    /* padding-bottom: 15px; */
}

.container > *, .container > .topBar > * {
    margin-bottom: 0px;
}

.container > .topBar {
    width: 96.52631579%;/*917px;*/
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: auto;
    margin-top: 15px;
    margin-bottom: 15px;
}

.title {
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height, or 91% */
    letter-spacing: 0.2px;
    color: var(--font-2);

    position: relative;
}

.collapsible {
    color:var(--font-2);
    margin-left: 15px;
    display: flex;
    flex-direction: row;
    cursor: pointer;
}

.chevronContainer {
    /* position: absolute; */
    width: auto;
    height: auto;
    /* left: calc(100% + 5px); */
    margin-left: 15px;
    top: 0px;
    cursor: pointer;
    transition: all 0.25s;
    
}

.sortAsc {
    transform: rotate(180deg);
}

.tableContainer {
    width: 96.52631579%;/*auto;*/
    /* height: 437px; */
    /* margin-top: 15px; */
    position: relative;   
    overflow: hidden; 
}

.devTable {
    width: 100%;/*917px;*/
    border-collapse: collapse;
    max-height: 437px;
    margin-top: 0px;
    overflow: hidden;
    margin-bottom: 15px;
}

.devTable, .devTable > thead > .tableHeader, .devTable > tr, .devTable td {
    border: none;
}

.devTable > thead > .tableHeader {
    height: 40px;
    /* margin-top:15px;
    margin-bottom: 15px; */
    pointer-events: all !important;
}

.devTable > thead > .tableHeader > th, .sortableColumn {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
    background-color: var(--bg-2);
    color: var(--font-3);
    pointer-events: all !important;
}

.sortableColumn {
    width: auto;
    /*max-width: 73px;*/
    line-height: 16px;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    pointer-events: all !important;
}

.sortableColumn > .chevron {
    width: auto;
    height: auto;
    cursor: pointer;
    transition: all 0.25s;
    margin-left: 4px;
    pointer-events: all !important;
}

.sortAscending {
    transform: rotate(180deg);
}

.devTable > thead > .tableHeader > th:first-child {
    padding-left: 23px;
    border-radius: 10px 0 0 10px;
}

.devTable > thead > .tableHeader > th:last-child {
    border-radius: 0px 10px 10px 0px;
}

.cm {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    /* identical to box height, or 167% */
    display: flex;
    align-items: center;

    color: var(--font-3);
}

.devTable > tbody {
    max-height: 100px;
    /* height: 100px; */
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 10px;
}

.devTable tr {
    border-bottom: 1.6px solid var(--bg-3);
}

.devTable td {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #ECE6FF;
    height: 41px;
   
}

.devTable tr:last-child {
    border: none;
}

.editButton {
    border:1px solid var(--font-1);
    border-radius: 5px;
    margin: 5px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    align-self: center;
    cursor:pointer;
    width: 90%;
    background: var(--main-bg);
    transition: 0.5s;
    
}
.editButton:hover {
    border:1px solid rgba(255, 172, 23, 0.5);
    background: #432C88;
}

.devTable  .dataRow .editButton:hover{
    background: none;
    /* border:1.6px solid #ECE6FF; */
}

.upgradeLock {
    height: 369px;
    position: absolute;
    right:0px;
    /*left: 0px;*/
    top: 49px;/*0px;*/

    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(40, 27, 79, 0.8);
}

.devTable > tbody > tr > td:first-child {
    padding-left: 23px;
}

.dataRowHighlight {
    background: rgba(255, 172, 23, 0.5);

    transition: 0.3s;
}
/* .devTable .dataRow:hover {
    background: rgba(255, 172, 23, 0.5);
} */

.deviceDot {
    width: 12px;
    height: 12px;
    border-radius: 6px;
    border: none;
    margin-left: 22px;
}

.devTable .dataRow:hover .deviceDot {
    border: 1.4px solid #D5C6FF;
}

.pageControl {
    height: 0px;
    overflow: hidden;
}
.popoverHeader {
    background-color: var(--bg-2);
}
.gridContainer {
    padding-top: 2rem;
    width: calc(100% - 321px);
    display: grid;
    /* grid-template-columns: repeat(4, 1fr); */
    /* grid-template-rows: 10rem 10rem 10rem 10rem 10rem 10rem; */
    grid-gap: 1rem;
    /* grid-auto-flow: column; */
}

.rightSide {
    display: flex;
    flex-direction: row;
    width: 50%;
    justify-content: flex-end;
}

@media (min-width: 1781px){
    .gridContainer {
        grid-template-columns: repeat(8, 1fr);
    }
}

@media (min-width: 1581px) and (max-width: 1780px){
    .gridContainer {
        grid-template-columns: repeat(7, 1fr);
    }
}
@media (min-width: 1381px) and (max-width: 1580px){
    .gridContainer {
        grid-template-columns: repeat(6, 1fr);
    }
}
@media (min-width: 1181px) and (max-width: 1380px){
    .gridContainer {
        grid-template-columns: repeat(5, 1fr);
    }
}
@media (min-width: 1000px) and (max-width: 1180px) {
    .gridContainer {
        grid-template-columns: repeat(4, 1fr);
    }
}
@media (min-width: 630px) and (max-width: 999px) {
    .gridContainer {
        grid-template-columns: repeat(3, 1fr);
    }
}
@media (min-width: 430px) and (max-width: 629px) {
    .gridContainer {
        grid-template-columns: repeat(2, 1fr);
    }
}
@media (max-width: 429px) {
    .gridContainer {
        grid-template-columns: repeat(1, 1fr);
    }
}

.gridItem {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    height: 10rem;
    width: 10rem;
    position: relative;
}
.gridItem div {
    position: absolute;
    left: 50%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}
.gridItem div button{
    margin: 0 0 10% 10%;
    border: none;
    background-color: rgba(40, 27, 79, 0.7);
    color: whitesmoke;
    transform: translateY(70%);
}

.gridAdd {
    background-size: 5rem 5rem;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    /* color: black; */
    height: 5rem;
    width: 5rem;
    margin: 2.5rem;
}
.gridAddContainer {
    height: 10rem;
    width: 10rem;
}

@media screen and (min-width:601px) and (max-width: 860px) {
    .container {
        /* width: calc(50% - 10px); */
        margin-top: 22px;
        width: 80vw;
    }
    .gridContainer {
        width: 80vw;
    }
}

@media screen and (min-width:601px) and (max-width:800px) {
    .container > .topBar {
        flex-direction: column;
        align-items: flex-start;
    }

    .title {
        padding-bottom: 10px;
    }

    .tableContainer, .devTable {        
        max-height: 375px;
    }
}

@media screen and (max-width: 600px){
    .container {
        width: 90vw;
        /* min-height: 587px; */
        margin: 10px 0px 0px;
        padding: 0px;
        border-radius: 10px;
    }

    .container > *, .container > .topBar {
        width: 100%;
    }

    .gridContainer {
        width: 90vw;
    }

    .topBar {
        display:flex;        
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        margin-top: 22px;
        flex-direction: row;
    }

    .title {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        /* identical to box height, or 125% */
                
        letter-spacing: 0.2px;
        /* width: 100%; */
        padding-left: 15px;
    }    

    .selectContainer {
        width: 100%;
        padding-left: 36px;
        margin-top: 16px;
    }

    .tableContainer {
        width:100%;
        /* min-height: 417px; */
        height: auto;
        margin-top: 17px;
    }

    .devTable {
        width: auto;
        /* max-height: auto; */
    }

    /* .devTable, .devTable > thead > .tableHeader, .devTable > tr, .devTable td {
        height: 40px;
    } */

    .devTable > thead > .tableHeader > th, .sortableColumn {
        font-size: 14px;
    }

    .devTable > thead > .tableHeader > th:first-child, .devTable > thead > .tableHeader > th:last-child {
        border-radius: 0px;
    }
    .devTable > thead > .tableHeader > th:first-child {
        padding-left: 15px;
    }
    .devTable > tbody > tr > td:first-child {
        padding-left: 15px;
    }

    .devTable td {
        border: none;
    }

    .topBorder {
        width: 85.024154589372vw;
        height: 0px;
        border-top: 1.6px solid #462D91;
    }

    .pageControl {
        width: 100%;
        height: 55px;
        background-color: var(--main-bg);

        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .pageInfo {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        /* or 167% */

        color: #AB8DFF;

        margin-left: 36px;
    }

    .pageButtons {
        display:flex;
        justify-content: space-between;
        align-items: center;

        margin-right: 36px;
    }

    .pageButton {
        width: 34px;
        height: 34px;        

        background-color: var(--bg-2);
        border-radius: 6px;
        cursor:pointer;

        display: flex;
        justify-content: center;
        align-items: center;
    }

    .pageButton:last-of-type {
        margin-left: 10px;
    }

}