.container {
    width: calc(100vw - 93px);/*100%;*/
    max-width: calc(100vw - 93px);
    min-height: 100vh;
    background-color: var(--dark);
    font-family: "Poppins";
    color: #ffffff;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.container > h3 {
    margin-top: 20px;
    text-align: center;
    color:#ffffff;
}

@media screen and (max-width:600px) {
    .container {
        min-width: 100vw;
        min-height: calc(100vh - 143.79px);
        justify-content: center;
    }
}