.container {
  width: 100vw;
  height: 50px;

  background: #20173a;
  mix-blend-mode: normal;

  display: flex;
  justify-content: center;
  align-items: stretch;
  position: fixed;
  z-index: 999; /* Ensure this is higher than the sidebar */
  /*grid-template-columns: 219px auto;*/
}

.pageLabelMobile {
  display: none;
}

.logoContainer,
.toolbarContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.logoContainer {
  position: relative;
  z-index: 1000; /* Ensure this is higher than both sidebar and navbar container */
  display: flex;
  align-items: center;
  padding-left: 10px; /* Adjust padding as needed */
  flex: 1 1 219px;
}

.logoContainer svg {
  width: 185px;
  height: 45px;
  display: block; /* Ensure the SVG is displayed as a block element */
}

.toolbarContainer {
  flex: 1 1 calc(100vw - 219px);
}

.toolbarContainer > * {
  margin-left: 0px;
}

.inputContainer {
  padding: 0px;
  margin-right: 22px;
  position: relative;
}

.input {
  width: 208px;
  /* height: 52px; */
  padding: 8px;
  background: rgba(187, 221, 255, 0.3);
  backdrop-filter: blur(4px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 10px;
  border: 1px solid;

  border-image-source: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.161) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

.input::placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  /*line-height: 29px;
    or 207% */

  color: #e9f3ff;
}

.searchIcon {
  position: absolute;
  right: 10px;
  top: 10px;
  pointer-events: none;
  margin: 0px;
  padding: 0px;
}

.menuWithIcon {
  width: 44px;
  height: 44px;
  border-radius: 22px;

  background: #421c80;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  cursor: pointer;
}

.userInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  /* height: 170px; */
  /* max-height: 170px; */
  margin-right: 100px;
  height: 50px;
}

.user {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  /* identical to box height, or 125% */

  text-align: left;

  color: #ece6ff;
  /* margin-bottom: 1.5px; */
  margin: 0px;
}

.tier {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 167% */

  text-align: left;
  text-transform: uppercase;

  color: #aa86e6;
  /* margin-top: 1.5px; */
  margin: 0px;
}

.topModal {
  background-color: #e9f3ff;
  border-radius: 5px;
  padding: 10px;
  position: absolute;
  bottom: 0;
  z-index: 9999;
}

.dropdown_toggle::after {
  display: none !important;
}

.topModal > h4 {
  font-size: smaller;
  color: #aa86e6;
}

@media screen and (min-width: 601px) and (max-width: 800px) {
  .logoContainer {
    flex: 1 1 190px; /*logo width figma 185px plus left margin 5px....*/
    justify-content: flex-start;
  }

  .toolbarContainer {
    flex: 1 1 calc(100vw - 190px);
  }

  .logoContainer > img {
    margin-left: 5px;
  }
  .inputContainer,
  .menuWithIcon {
    margin-left: 2.5px;
    margin-right: 2.5px;
  }

  .userInfo {
    margin-left: 2.5px;
    margin-right: 7.5px;
  }
}

@media screen and (max-width: 600px) {
  .container {
    height: 80px;
    height: 48px;
    max-height: 80px;
    align-items: flex-end;
    position: relative;
  }

  .pageLabelMobile {
    display: block;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 111% */

    display: flex;
    align-items: center;
    letter-spacing: 0.2px;

    color: #ece9ff;

    padding-bottom: 18px;
  }

  .logoContainer {
    /*      flex: 1 1 40%;
        justify-content: center;*/
    display: none;
  }
  /*
    .logoContainer > img {
        width: 95%;
    }*/

  .toolbarContainer {
    /*flex: 1 1 60%;*/
    align-items: flex-end;
    justify-content: flex-end;
    position: absolute;
    right: 0px;
    top: 0px;
    width: 56px;
    height: 100%;
  }
  /*
    .toolbarContainer > * {
        margin: auto;
    }*/

  .inputContainer {
    /*width: calc(90% - (2*33px));*/
    display: none;
  }
  /*
    .input {
        width: 100%;
        padding-left: 11px;
    }

    .searchIcon {        
        right: 11px;
    }*/

  .menuWithIcon {
    width: 33px;
    height: 33px;
    border-radius: 16.5px;
  }

  .showAtMobile {
    margin-left: 0px;
    margin-bottom: 10px;
  }

  .hideAtMobile {
    display: none;
  }

  .menuIcon,
  .userInfo {
    display: none;
  }
}
