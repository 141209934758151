.deviceCount {
  pointer-events: none;
}

.zoomButtons {
  position: absolute;
  right: 0px;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  width: 100%;
  height: auto;
  margin-bottom: 18px;
  pointer-events: none;
}

.zoomButton {
  width: 45px;
  height: 45px;
  border-radius: 8.85px;
  cursor: pointer;
  pointer-events: all;
  background: rgba(250, 251, 252, 0.25);
  backdrop-filter: blur(3.53846px);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
}

.zoomButton:hover {
  border: 1px solid #ffffff;
}

.toggleCenter {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: rgba(47, 33, 88, 0.7);
  position: absolute;
  left: 5px;
  bottom: 5px;
  cursor: pointer;
  transition: all 0.25s;

  display: flex;
  justify-content: center;
  align-items: center;
}

.centerToggle {
  width: 60%;
  height: 60%;
  border-radius: 9px;
  background-color: #42267d;
  opacity: 1;
  transition: all 0.25s;
}

.hideCenterToggle {
  opacity: 0;
}

@media screen and (max-width: 600px) {
  .beaconLbl {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    /* identical to box height */

    margin: 0px;
    padding: 0px;
    letter-spacing: 0.282353px;
    text-transform: uppercase;

    color: #ece5ff;
  }

  .deviceCount {
    width: 59.90338164251208vw; /*248px;*/
    height: 33px;

    background: #34216b;
    border: 1px solid #34267d;

    position: absolute;
    left: 20.04830917874396vw; /*83px*/
    top: 0px;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .countInfo {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    /* identical to box height, or 167% */
    color: #ece6ff;

    margin: 0px;
    padding: 0px;
    text-align: center;
  }

  .countNum {
    font-weight: 700;
    font-size: 12px;

    color: #ffffff;
  }

  .zoomButtons {
    margin-bottom: 42px; /*131px - 89px swipe height;*/
  }

  .zoomButton {
    width: 34px;
    height: 34px;

    background: rgba(250, 251, 252, 0.25);
    backdrop-filter: blur(3.53846px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 8.84615px;
    margin-right: 5.31400966183575vw; /*22px;*/
  }

  .toggleCenter {
    bottom: calc(100% - 35px);
  }
}
