html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #181818; /* Updated to dark gray */
}

.device-count-card {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.device-count-header {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.device-icon {
  margin-right: 8px;
  color: #6e6b7b;
}

.device-title {
  font-size: 0.9rem;
  color: #6e6b7b;
  font-weight: 500;
}

.device-count {
  font-size: 2rem;
  font-weight: 600;
  color: #5e5873;
}

.container {
  padding: 20px;
  background-color: #181818; /* Updated to dark gray */
  color: #ffffff;
}

.logoContainer {
  text-align: center;
  margin-bottom: 20px;
}

.logo {
  max-width: 200px; /* Adjust as needed */
  height: auto;
}

.cardsWrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.card {
  background-color: #281b4f; /* Deep purple for cards */
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  margin-bottom: 15px;
  position: relative; /* Add this */
  z-index: 1; /* Add this */
  color: white; /* Add this line to make all text white */
}

.cardHeader {
  padding: 10px 15px;
  background-color: #281b4f; /* Changed to match the card background */
  border-bottom: 1px solid #393953; /* Keep this for a subtle separation */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.locationName {
  font-size: 1.4em; /* Slightly increased for better visibility */
  font-weight: 600;
  color: #ffffff; /* White text for location name */
  margin-bottom: 5px;
}

.plexyzId {
  font-size: 0.9em; /* Slightly increased */
  color: #a2a3b7; /* Light gray-purple for secondary text */
}

.mapSection {
  height: 150px; /* Reduced height */
  border-top: 1px solid #393953;
  border-bottom: 1px solid #393953;
  position: relative; /* Add this */
  z-index: 1; /* Add this */
}

.infoSection {
  padding: 10px 15px;
  background-color: #281b4f; /* Explicitly set to ensure consistency */
}

.infoHeader,
.infoContent {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 5px;
  text-align: center;
}

.infoHeader {
  font-size: 0.8em;
  color: #a2a3b7; /* Light gray for info headers */
  margin-bottom: 5px;
}

.infoContent {
  font-size: 0.9em;
  font-weight: 600;
  color: #ffffff; /* White text for info content */
}

.infoContent span:first-child {
  font-size: 2.5em; /* Increased font size for the count */
  color: #ffffff; /* White color for the count */
  font-weight: 700; /* Make it bold */
  line-height: 1.2; /* Adjust line height for better spacing */
}

.status {
  color: #f64e60; /* Red color for 'Offline' status */
}

.status.active {
  color: #0bb783; /* Green color for 'Active' status */
}

.cardGraph {
  display: flex;
  align-items: flex-end;
  height: 40px;
  gap: 3px;
}

.graphBar {
  width: 6px;
  background-color: #3a3a6a;
  height: 100%;
}

.graphBar:nth-child(2) {
  height: 60%;
}

.graphBar:nth-child(3) {
  height: 80%;
}

@media (max-width: 768px) {
  .cardsWrapper {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}

.topBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  background-color: #2c2c54;
  padding: 15px;
  border-radius: 8px;
}

.title {
  font-size: 1.8em;
  color: #fff;
  margin: 0;
}

.lastUpdated {
  font-size: 1em;
  color: #ccc;
}

.tablesWrapper {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
}

.tableRow {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  flex-wrap: wrap;
}

.tableContainer {
  flex: 1;
  min-width: 250px;
  max-width: calc(33.333% - 14px);
}

.devTable {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  background-color: #2b2b40;
  color: #ffffff;
}

.tableHeader {
  background-color: #323248;
}

.tableHeader th {
  padding: 15px;
  text-align: left;
}

.devTable th,
.devTable td {
  padding: 15px;
  text-align: left;
  border-bottom: 1px solid #393953;
}

.devTable th {
  font-size: 1.2em;
  font-weight: bold;
}

.devTable td {
  font-size: 1.1em;
}

.devTable tbody tr:last-child td {
  border-bottom: none;
}

.devTable tbody tr:hover {
  background-color: #323248;
}

.devTable thead .tableHeader {
  height: 40px;
}

.devTable thead .tableHeader th {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  background-color: var(--bg-2);
  color: var(--font-3);
}

.devTable tbody td {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #ece6ff;
  height: 41px;
}

.devTable tr {
  border-bottom: 1.6px solid var(--bg-3);
}

.devTable tr:last-child {
  border: none;
}

@media screen and (max-width: 600px) {
  .container {
    width: 100%;
    margin: 0;
    padding: 10px;
    border-radius: 0;
  }

  .topBar {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 22px;
  }

  .title {
    font-size: 16px;
    line-height: 20px;
    padding-left: 15px;
  }

  .tablesWrapper {
    width: 100%;
    flex-direction: column;
  }

  .tableContainer {
    width: 100%;
  }

  .devTable thead .tableHeader th {
    font-size: 14px;
  }

  .devTable thead .tableHeader th:first-child {
    padding-left: 15px;
  }

  .devTable tbody td:first-child {
    padding-left: 15px;
  }
}

@media (max-width: 768px) {
  .tablesWrapper {
    flex-direction: column;
  }

  .tableContainer {
    max-width: 100%;
  }
}

@media (max-width: 480px) {
  .cardsWrapper {
    grid-template-columns: 1fr;
  }
}

/* Styles for buttons */
.button {
  background-color: #323248;
  color: #ffffff;
  border: 1px solid #393953;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 0.9em;
}

.button:hover {
  background-color: #393953;
}

/* Pagination styles */
.pagination {
  color: #a2a3b7;
  font-size: 0.9em;
  margin-top: 15px;
}

.paginationButton {
  background-color: #3699ff;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  margin: 0 5px;
  cursor: pointer;
}

.paginationButton:hover {
  background-color: #187de4;
}

/* Add unit button style */
.addUnitButton {
  background-color: #8950fc;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
}

.addUnitButton:hover {
  background-color: #7337ee;
}

.analyticsWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.analyticsCard {
  background-color: #281b4f;
  border-radius: 8px;
  padding: 15px;
  width: 23%;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  color: white; /* Add this line to make all text white */
}

.analyticsCard h3 {
  margin: 0 0 10px 0;
  font-size: 16px;
  color: white; /* Ensure the title is white */
}

.analyticsCard p {
  margin: 0;
  font-size: 24px;
  font-weight: bold;
  color: white; /* Ensure the number is white */
}

.blue {
  border-top: 4px solid #3498db;
}
.green {
  border-top: 4px solid #2ecc71;
}
.red {
  border-top: 4px solid #e74c3c;
}
.purple {
  border-top: 4px solid #9b59b6;
}

/* Existing styles... */

@media (max-width: 768px) {
  .analyticsWrapper {
    flex-wrap: wrap;
  }

  .analyticsCard {
    width: 48%;
    margin-bottom: 10px;
  }
}
