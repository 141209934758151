.container {
    width: 157px;
    height: 134px;
    background-color: rgba(172, 27, 27, 0);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-family: "Poppins";
}

.container > * {
    border-bottom: 0px;
}

.lockLogo {
    width: auto;
    height: auto;
}

.label {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    /* or 125% */

    text-align: center;
    letter-spacing: 0.2px;

    color: #865ACE;
}

.label::first-line {
    font-size: 16px;
}