.container {
    width: 287px;
    height: 432px;
    background-color: var(--main-bg);
    font-family: "Poppins";
    border-radius: 15px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    pointer-events: all;    
}

.container, .container * {
    transition:all 0.25s;
}

.container > * {
    width: 248px;
    margin-bottom: 0px;
    margin-top: 0px;
}

.closed {
    width: 40px;
    border-radius: 15px 0px 0px 15px;
}

.closed *:not(.nextArrow) {
    opacity: 0;
}

.closed .nextArrow, .closed .nextArrow * {
    opacity: 1;
}

.nextArrow {
    width: 100%;
    height: 18px;
    background-color: var(--bg-1);
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.nextArrow > svg {
    margin-right: 15px;
    cursor: pointer;
}

.closed .nextArrow svg {
    transform: rotate(180deg);
}

.container > .title {
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 20px;
    /* identical to box height, or 91% */
    letter-spacing: 0.2px;
    color: var(--font-2);
    margin-top: 24px;
}

.container p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* or 143% */
    color: var(--font-1);
}

.container > p:first-of-type {
    margin-top: 32px;
}

.container > p:last-of-type {
    margin-top: 36px;
}

.colorContainer, .distanceContainer {
    height: auto;
    margin: 0px;
    padding: 0px;
}

.colorContainer {
    margin-top: 9px;/*random estimation, cannot measure from figma */
}

.colorRow, .distanceRow {    
    display:flex;
    justify-content: flex-start;
    align-items: center;
}

.colorRow {
    height:40px;
}

.colorRow > p, .distanceRow > p {
    margin-bottom: 0px;
    margin-top: 0px;
    line-height: 14px;
    margin-left: 16px;
}

.colorRow > .colorDot {
    width: 28px;
    height: 28px;
    border-radius: 14px;
}

.distanceRow {
    height: 32px;
}

.distanceRow > .distanceSquare {
    width: 28px;
    height: 12px;
    border: 1px solid rgba(170, 134, 229, 0.5);
}

@media screen and (max-width: 600px) {
    .container {
        width: 100%;
        height: auto;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 0px;
    }    

    .container > *, .container > p:first-of-type {
        width: 72.70531400966184vw;
        margin-left: 9.17874396135266vw;/*38px;*/
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .container > p:last-of-type {
        margin-top: 5.1511758118701vh;/*46px*/
    }
    .container p {
        font-size: 12px;
        line-height: 20px;
    }

    .nextArrow, .container > .title {
        display: none;
        height: 0px;
        overflow: hidden;
    }

    .colorContainer {
        display: flex;
        margin-top: 12px;
        flex-wrap: wrap;
    }

    .colorRow {
        height: 20px;
    }

    .colorRow > .colorDot {
        width: 20px;
        height: 20px;
        border-radius: 10px;
    }

    .colorRow > p {
        margin-left: 7px;
        margin-right: 2.367149758454106vw;
    }

    .distanceRow {
        height: 12px;
        margin-top: 13px;
        margin-bottom: 11px;
    }
    .distanceRow > .distanceSquare {
        width: 31px;
    }

    .distanceRow > p {
        margin-left: 9px;
        line-height: 12px;
    }
}

