@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --main-bg: #281b4f;
    --dark: #181818;
    --white: #ffffff;

    --bg-1: #34216B;
    --bg-2: #432C88;
    --bg-3: #462D90;

    --bg-4: #40316b;
    --bg-5: #462D91;/*still very few use when listed...*/

    --blue-dot: #5C0FFF;
    --yellow-dot: #FFAC17;

    --font-1: #ECE6FF;
    --font-2: #C8BDE8;
    /*only very few using these....*/
    --font-3: #ECE5FF;
    --font-4: #ECE9FF;
    --font-5: #AA86E6;
    
}